/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import customerService from "../../Services/CustomerService";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
export default function BindCustomerPopup({
  bool,
  setbool,
  getUsers,
  userID,
  BindedCustomers,
}) {
  const [cookies] = useCookies(["JWTtoken"]);
  const [CustomerID, setCustomerID] = React.useState();
  const [customers, setcustomers] = useState();

  const handleClose = () => {
    setbool(false);
    setCustomerID("");
  };
  const handleChange = (event) => {
    setCustomerID(event.target.value);
  };

  useEffect(() => {
    customerService.GetCustomers(cookies.JWTtoken).then((data) => {
      setcustomers(data.data);
    });
  }, []);

  useEffect(() => {
    setcustomers(
      customers?.filter((item) => {
        return Array.from(BindedCustomers).indexOf(item.CustomerID) === -1;
      })
    );
  }, [bool]);

  const BindCustomer = () => {
    customerService
      .bindCustomer(cookies.JWTtoken, {
        CustomerID: parseInt(CustomerID),
        UserID: userID,
      })
      .then((data) => {
        getUsers();
        handleClose();
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        getUsers();
        console.log(error);
      });
  };

  return (
    <div>
      <Dialog
        open={bool}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          sx={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle sx={{ fontWeight: "bold", pb: 0, mb: 0 }}>
            Bind Customer
          </DialogTitle>
        </Box>

        <DialogContent sx={{ maxWidth: "350px", pt: 0, mt: 1 }}>
          <Box>
            <FormControl sx={{ width: "200px" }}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Customer ID
              </InputLabel>
              <Select
                sx={{ width: "100%", m: 1 }}
                label="Customer ID"
                value={CustomerID ? CustomerID : ""}
                onChange={handleChange}
              >
                {customers?.map((customer, index) => {
                  return (
                    <MenuItem key={index} value={customer.CustomerID}>
                      {customer.CustomerID + " | " + customer.Alias}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Box m={2}>
              <Button
                onClick={(e) => {
                  BindCustomer();
                }}
                variant="contained"
              >
                Bind
              </Button>
            </Box>
            <Box m={2}>
              <Button
                onClick={(e) => {
                  handleClose();
                }}
                variant="contained"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
