import { Box } from "@mui/material";
import React from "react";
import TopWidgets from "./TopWidgets";

export default function Home(props) {
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <TopWidgets />
      </Box>
    </>
  );
}
