import React, { useEffect, useState } from "react";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useCookies } from "react-cookie";
import { Box } from "@mui/material";
import EducationTable from "./components/EducationTable";
import AddEditEducation from "./components/AddEditEducation/AddEditEducation";
import educationService from "../../Services/EducationService";

const Educations = () => {
  const [cookies] = useCookies(["JWTtoken"]);
  const [loading, setLoading] = useState(false);
  const [educations, setEducations] = useState([]);
  const [addEditEducation, setAddEditEducation] = useState(false);

  const getEducations = () => {
    setLoading(true);
    educationService
      .GetEducations(cookies.JWTtoken)
      .then((data) => {
        setEducations(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.data === "Forbidden") {
          window.location.href = "/";
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };
  useEffect(() => {
    getEducations();
  }, []);

  return (
    <>
      <LoadingScreen bool={loading} />
      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: 16, right: "1%", color: "white" }}
        onClick={(e) => {
          setAddEditEducation(true);
        }}
      >
        <AddIcon />
      </Fab>
      <Box sx={{ width: "100%" }}>
        <EducationTable educations={educations} getEducations={getEducations} />
      </Box>
      {addEditEducation && (
        <AddEditEducation
          mode="Add"
          open={addEditEducation}
          onClose={() => {
            setAddEditEducation(false);
          }}
          onSuccess={() => {
            setAddEditEducation(false);
            getEducations();
          }}
        />
      )}
    </>
  );
};

export default Educations;
