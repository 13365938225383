import { useCookies } from "react-cookie";
import { Box, Button } from "@mui/material";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import { useState, useEffect } from "react";
import searchService from "../../Services/searchService";
import { useLocation, useNavigate } from "react-router-dom";
import SearchDetailTable from "./SearchDetailTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TextField } from "@mui/material";
const SearchDetailPage = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["JWTtoken"]);
  const [loading, setloading] = useState(false);
  const [result, setResult] = useState();
  const [search, setSearch] = useState("");
  const [resetArray, setResetArray] = useState([]);

  const location = useLocation();

  const { state } = location;

  const callSearch = () => {
    setloading(true);
    searchService
      .searchObject(state.id, cookies.JWTtoken)
      .then((data) => {
        setloading(false);
        setResult(data.data);
        setResetArray(data.data);
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
      });
  };

  useEffect(() => {
    callSearch();
  }, [state.id]);

  useEffect(() => {
    const filterResult = () => {
      let tempArray = result;
      const filteredItems = search
        ? tempArray?.filter((item) =>
            Object.values(item).some((value) =>
              value?.toString().toLowerCase().includes(search.toLowerCase())
            )
          )
        : resetArray;
      if (filteredItems?.length > 0) {
        setResult(filteredItems);
      } else {
        setResult(resetArray);
      }
    };
    filterResult();
  }, [search]);

  return (
    <>
      <Box
        pt={10}
        sx={{ marginLeft: "220px", display: "flex", flexDirection: "row" }}
      >
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          sx={{ color: "white" }}
          onClick={() => {
            navigate("/search", { state: { search: state.search } });
          }}
        >
          Back
        </Button>

        <Box
          sx={{
            width: "50%",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            paddingLeft: "100px",
          }}
        >
          <TextField
            id="filled-search"
            label="Search field"
            type="search"
            value={search}
            variant="standard"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Box>
      </Box>
      <LoadingScreen bool={loading} />
      <Box sx={{ width: "100%" }} mt={2}>
        <SearchDetailTable
          result={result}
          setResult={setResult}
          setResetArray={setResetArray}
        />
      </Box>
    </>
  );
};

export default SearchDetailPage;
