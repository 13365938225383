import React, { useEffect, useState } from "react";
import userService from "../../../Services/UserService";
import OtpInput from "react-otp-input";
import { Box, Typography } from "@mui/material";

const OtpHandler = ({ token, email, onSuccess }) => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  useEffect(() => {
    setError(false);
    if (otp.length === 6) {
      userService
        .verify2FA({ email, token: otp })
        .then((data) => {
          onSuccess();
        })
        .catch((error) => {
          setError(true);
        });
    }
  }, [otp]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        separator={<span style={{ width: "8px" }}>-</span>}
        isInputNum={true}
        shouldAutoFocus={true}
        renderInput={(props) => <input {...props} />}
        inputStyle={{
          border: "1px solid black",
          borderRadius: "8px",
          margin: "5px",
          width: "44px",
          height: "44px",
          fontSize: "12px",
          color: "#000",
          fontWeight: "400",
          caretColor: "blue",
        }}
        focusStyle={{
          border: "1px solid #CFD3DB",
          outline: "none",
        }}
      />
      {error && <Typography color="red">Incorrect OTP</Typography>}
    </Box>
  );
};

export default OtpHandler;
