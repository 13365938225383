import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import { useCookies } from "react-cookie";
import locationService from "../../Services/LocationService";
export default function LocationTableRows({ row, getlocations }) {
  const [cookies] = useCookies(["JWTtoken"]);

  const deleteRequest = () => {
    locationService
      .deleteLocation(row.locationID, cookies.JWTtoken)
      .then((data) => {
        getlocations();
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <TableRow
        key={row.logID}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>{row.objectnr}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>
          <IconButton
            onClick={() => {
              deleteRequest();
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
