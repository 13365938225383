import { api } from "./api";

class CustomerService {
  GetCustomers = (token) =>
    api.get("customers/", { headers: { Authorization: `Bearer ${token}` } });

  deleteCustomers = (id, token) =>
    api.delete("customers/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    });

  addCustomer = (token, data) =>
    api.post("customers/", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  bindCustomer = (token, data) =>
    api.post("customers/bind", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  unBindCustomer = (token, data) =>
    api.put("customers/bind", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  editCustomer = (token, data) =>
    api.put("customers", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  bindLocation = (token, data) =>
    api.post("customers/bindlocation", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  unBindLocation = (token, data) =>
    api.put("customers/bindlocation", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
}
let customerService = new CustomerService();
export default customerService;
