import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCookies } from "react-cookie";
import customerService from "../../Services/CustomerService";
import DeletePopup from "../../Components/Popups/DeletePopup";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CreateCustomerPopup from "../../Components/Popups/CreateCustomerPopup";

export default function TableRows({ row, getCustomers }) {
  const [cookies] = useCookies(["JWTtoken"]);

  const [deletePopup, setDeletePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);

  const handleClose = () => {
    setDeletePopup(!deletePopup);
  };

  const deleteCustomer = () => {
    customerService
      .deleteCustomers(row.CustomerID, cookies.JWTtoken)
      .then((data) => {
        getCustomers();
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <TableRow
        key={row.CustomerID}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>{row.CustomerID}</TableCell>
        <TableCell>{row.Alias}</TableCell>

        <TableCell>
          <IconButton
            onClick={() => {
              setEditPopup(true);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeletePopup(true);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>

      {deletePopup && (
        <DeletePopup
          confirmDelete={deleteCustomer}
          bool={deletePopup}
          handleClose={handleClose}
        />
      )}

      {editPopup && (
        <CreateCustomerPopup
          mode="edit"
          bool={editPopup}
          setbool={setEditPopup}
          getCustomers={getCustomers}
          editData={{ alias: row.Alias, customerId: row.CustomerID }}
        />
      )}
    </>
  );
}
