import React from "react";
import {
  TextField,
  Card,
  Box,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import userService from "../../Services/UserService";
const Title = styled(Typography)({ fontSize: "24px", fontWeight: "bold" });

const ForgotPassword = (props) => {
  const [email, setEmail] = React.useState("");
  const [loading, setloading] = React.useState(false);

  const sendOtp = () => {
    setloading(true);
    userService
      .reqToken({ email })
      .then((data) => {
        setloading(false);
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((err) => {
        setloading(false);
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };
  return (
    <>
      <LoadingScreen bool={loading} />
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "220px",
          }}
        >
          <Card sx={{ minWidth: 300, maxWidth: 300, padding: "20px" }}>
            <CardContent>
              <Title align="center">FORGOT PASSWORD</Title>
              <Box sx={{}}>
                <>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        sendOtp();
                        // write your functionality here
                      }
                    }}
                  />
                </>

                <Box mt={2}>
                  <Button
                    variant="contained"
                    disabled={email ? false : true}
                    sx={{ margin: "0px", width: "100%" }}
                    onClick={async (e) => {
                      sendOtp();
                    }}
                  >
                    Send OTP
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
