import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import {
  IconButton,
  FormControl,
  InputLabel,
  Box,
  Select,
} from "@mui/material";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SelectComponent from "./SelectComponent";
import { useCookies } from "react-cookie";
import locationService from "../../Services/LocationService";
import BindCustomerToLocationPopup from "../../Components/Popups/BindCustomerToLocationPopup";
import DeletePopup from "../../Components/Popups/DeletePopup";
import AddLocationPopup from "../../Components/Popups/AddLocationPopup";
import EditIcon from "@mui/icons-material/Edit";
export default function LocationTableRows({ row, getLocations }) {
  const [cookies] = useCookies(["JWTtoken"]);
  const [bindBool, setBindBool] = useState(false);
  const [check, setCheck] = useState(true);
  const [editLocation, setEditLocation] = useState(false);

  const [deletePopup, setDeletePopup] = useState(false);

  useEffect(() => {
    if (row.customers.length < 1) {
      setCheck(false);
    }
  }, [row.customers]);
  const handleClose = () => {
    setDeletePopup(!deletePopup);
  };

  const handleChange = (_event) => {};

  const deleteRequest = () => {
    locationService
      .deleteLocation(row.locationID, cookies.JWTtoken)
      .then((data) => {
        getLocations();
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <TableRow
        key={row.locationID}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>{row.locationID}</TableCell>
        <TableCell>{row.locationCustomID}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>
          <FormControl sx={{ m: -1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small">Customers</InputLabel>
            <Select
              value={row.customers[0] ? row.customers[0].Alias : ""}
              onChange={handleChange}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  align: "center",
                  justifyContent: "center",
                }}
              >
                <Box>
                  {row.customers.map((data) => {
                    return (
                      <Box mr={1} ml={1} key={data}>
                        <SelectComponent
                          setCheck={setCheck}
                          locationID={row.locationID}
                          data={data}
                          getLocations={getLocations}
                        />
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    disabled={check}
                    onClick={(e) => {
                      setBindBool(true);
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </Select>
          </FormControl>
        </TableCell>

        <TableCell>
          <IconButton
            onClick={() => {
              setEditLocation(true);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeletePopup(true);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>

      <BindCustomerToLocationPopup
        bool={bindBool}
        setbool={setBindBool}
        locationID={row.locationID}
        getLocations={getLocations}
        BindedCustomers={row.customers}
      />
      <DeletePopup
        confirmDelete={deleteRequest}
        bool={deletePopup}
        handleClose={handleClose}
      />
      {editLocation && (
        <AddLocationPopup
          mode="edit"
          bool={editLocation}
          setBool={setEditLocation}
          getLocations={getLocations}
          editData={{
            id: row.locationID,
            locationId: row.locationCustomID,
            desc: row.description,
          }}
        />
      )}
    </>
  );
}
