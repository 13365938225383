import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";

export default function SearchRow({ row, search }) {
  const navigate = useNavigate();
  return (
    <>
      <TableRow
        key={row.requestID}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/search/details/", {
            state: { id: row.RELID, search: search },
          });
        }}
      >
        <TableCell>{row.RELID}</TableCell>
        <TableCell>
          {row.NAME1}
          {row.NAME2 ? " ," + row.NAME2 : " "}
          {row.NAME3 ? " ," + row.NAME3 : " "}
          {row.NAME3}
        </TableCell>
        <TableCell>
          {row.VISITADR}
          {row.VISITADRCITY ? " ," + row.VISITADRCITY : ""}
          {row.VISITADRSTREET ? " ," + row.VISITADRSTREET : ""}
          {row.VISITADRZIP ? " ," + row.VISITADRZIP : ""}
        </TableCell>
      </TableRow>
    </>
  );
}
