import React, { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useAppContext } from "../../context/AppContext";
import userService from "../../Services/UserService";
import ProtectedRoutes from "../ProtectedRoutes/ProtectedRoutes";
import PagesLayout from "../PagesLayout/PagesLayout";
import RootPathComponent from "../RootPathComponent/RootPathComponent";
import Customers from "../../pages/Customers/Customers";
import Declaration from "../../pages/Declaration/Declaration";
import SingleUserDeclaration from "../../pages/Declaration/components/singleUserDeclaration/SingleUserDeclaration";
import Gateway from "../../pages/GateWay/GateWay";
import Locations from "../../pages/Locations/Locations";
import LocationsLogs from "../../pages/LocationsLogs/LocationsLogs";
import Login from "../../pages/LoginPage/Login";
import RoleRequest from "../../pages/RoleRequests/RoleRequest";
import Tags from "../../pages/Tags/Tags";
import Users from "../../pages/Users/Users";
import ForgotPassword from "../../pages/forgotpassword/ForgotPassword";
import Home from "../../pages/home/Home";
import NewPassword from "../../pages/newpassword/NewPassword";
import SearchDetailPage from "../../pages/searchDetail/SearchDetailPage";
import SearchPage from "../../pages/searchPage/SearchPage";
import Educations from "../../pages/Educations/Educations";
import ProgressConversations from "../../pages/ProgressConversations/ProgressConversations";
import UserEducations from "../../pages/UserEducations/UserEducations";


const AppRouter = () => {
  const [cookies] = useCookies(["JWTtoken"]);
  const navigate = useNavigate()
  const {
    appState: { role , isLoggedIn},
    updateAppState,
  } = useAppContext();


  useEffect(() => {
    const initializeUserState = async () => {
      if (cookies.JWTtoken && cookies.JWTtoken !== "undefined") {
        const { role } =  userService.GetRole(cookies.JWTtoken);
        updateAppState({ isLoggedIn: true, role, loading: false });
      } else {
        updateAppState({ isLoggedIn: false, loading: false });
        navigate("/login")
      }
    };
    initializeUserState();
  }, [cookies.JWTtoken]);



  return (
    <div>
      <Routes>
        <Route path="/" element={<RootPathComponent />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/password/reset/:token" element={<NewPassword />} />
        <Route
          path="/"
          element={
            <ProtectedRoutes>
              <PagesLayout />
            </ProtectedRoutes>
          }
        >
          <Route index element={<Navigate to="/home" />} />
          <Route path="home" element={<Home />} />
          {role && role > 3 && (
            <>
              <Route path="customers" element={<Customers />} />
              <Route path="locations" element={<Locations />} />
              <Route path="search/details" element={<SearchDetailPage />} />
              <Route path="role-requests" element={<RoleRequest />} />
              <Route path="search" element={<SearchPage />} />
              <Route path="locationsLogs" element={<LocationsLogs />} />
              <Route path="tags" element={<Tags />} />
              <Route path="gateway" element={<Gateway />} />
              <Route path="users" element={<Users />} />
              <Route path="declarations-detail" element={<SingleUserDeclaration />} />
              <Route path="declarations/:id" element={<SingleUserDeclaration />} />
              <Route path="declarations" element={<Declaration />} />
              <Route path="educations" element={<Educations />} />
              <Route path="progress-conversations" element={<ProgressConversations />} />
              <Route path="user-education/:id" element={<UserEducations />} />
            </>
          )}
          {role && role > 2 && (
            <>
              <Route path="search/details" element={<SearchDetailPage />} />
              <Route path="search" element={<SearchPage />} />
            </>
          )}
          {!isLoggedIn &&
               <Route path="*" element={<RootPathComponent />} />
          }
        
        </Route>
      </Routes>
    </div>
  );
};

export default AppRouter;
