import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import TableRows from "./CustomersTableRows";
import { customersHeading } from "../../Common/tables-headings";
export default function TableComponent({ customers, getCustomers }) {
  return (
    <Box pt={10} ml={2} mr={2} sx={{ marginLeft: "220px" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {customersHeading.map((heading, index) => {
                return <TableCell key={index}>{heading}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((row) => (
              <TableRows
                row={row}
                key={row.CustomerID}
                getCustomers={getCustomers}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
