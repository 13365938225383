import { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { Button, Box } from "@mui/material";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import declarationService from "../../../../Services/DeclarationService";
import {
  currency,
  declarationStatus,
  declarationStatusColor,
  getNumberOfItems,
  getSumOfGrandTotal,
  getSumOfNonCostItems,
} from "../../declarationUtils";

export default function DeclarationTableRows({ row, getDeclarations, users }) {
  const [cookies] = useCookies(["JWTtoken"]);
  const [singleDeclaration, setSingleDeclaration] = useState();
  const navigate = useNavigate();

  const routeToUserDeclaration = () => {
    navigate(`/declarations/${row.id}`);
  };

  useEffect(() => {
    declarationService
      .getSingleDeclaration(cookies.JWTtoken, row.id)
      .then((declarations) => {
        setSingleDeclaration(declarations.data);
      });
  }, []);

  const items = singleDeclaration ? getNumberOfItems(singleDeclaration) : 0;
  const total = singleDeclaration ? getSumOfGrandTotal(singleDeclaration) : 0;

  const mileage = singleDeclaration
    ? getSumOfNonCostItems(singleDeclaration)
    : 0;

  const user = users.filter((user) => user.userID === row.userid)[0];

  const declineDeclaration = () => {
    declarationService.DeclineDeclaration(cookies.JWTtoken, row.id).then(() => {
      getDeclarations();
    });
  };
  const acceptDeclaration = () => {
    declarationService.ApproveDeclaration(cookies.JWTtoken, row.id).then(() => {
      getDeclarations();
    });
  };

  return (
    <>
      <TableRow
        key={row.userid}
        onClick={routeToUserDeclaration}
        sx={{
          cursor: "pointer",
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>{row.userid}</TableCell>
        <TableCell>
          {user?.firstname}&nbsp;{user?.lastname}
        </TableCell>
        <TableCell>
          {moment(row.declarationdate).format("DD/MM/YYYY HH:MM")}
        </TableCell>

        <TableCell>{row.banknumber}</TableCell>
        <TableCell>{row.note}</TableCell>
        <TableCell
          sx={{
            color: declarationStatusColor(row.approved),
            fontWeight: "bold",
          }}
        >
          {declarationStatus(row.approved)}
        </TableCell>
        <TableCell>{items}</TableCell>
        <TableCell>
          {total.toFixed(2)} {currency("Euro")}
        </TableCell>
        <TableCell>{mileage}</TableCell>
        <TableCell sx={{ zIndex: "999999" }}>
          {!row.approved ? (
            <Box sx={{ display: "flex" }}>
              <Box mr={1} ml={1}>
                <Button
                  sx={{
                    backgroundColor: declarationStatusColor(1),
                    "&:hover": { backgroundColor: declarationStatusColor(1) },
                  }}
                  onClick={(e) => {
                    acceptDeclaration();
                    e.stopPropagation();
                  }}
                >
                  Approve
                </Button>
              </Box>
              <Box mr={1} ml={1}>
                <Button
                  sx={{
                    backgroundColor: declarationStatusColor(2),
                    "&:hover": { backgroundColor: declarationStatusColor(2) },
                  }}
                  onClick={(e) => {
                    declineDeclaration();
                    e.stopPropagation();
                  }}
                >
                  Decline
                </Button>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
