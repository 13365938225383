import React from "react";
import { TableRow, TableCell } from "@mui/material";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCookies } from "react-cookie";

import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import AddEditEducation from "./AddEditEducation/AddEditEducation";
import DeletePopup from "../../../Components/Popups/DeletePopup";
import educationService from "../../../Services/EducationService";

const EducationTableRows = ({ education, getEducations }) => {
  const [cookies] = useCookies();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const deleteEducation = () => {
    educationService
      .deleteEducations(education.educationID, cookies.JWTtoken)
      .then((data) => {
        getEducations();
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>{education.educationID}</TableCell>
        <TableCell>{education.type}</TableCell>
        <TableCell>{education.description}</TableCell>
        <TableCell>{education.customers}</TableCell>

        <TableCell>
          <IconButton
            onClick={() => {
              setEditModal(true);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeleteModal(true);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
      {editModal && (
        <AddEditEducation
          mode="Edit"
          open={editModal}
          onClose={() => {
            setEditModal(false);
          }}
          onSuccess={() => {
            setEditModal(false);
            getEducations();
          }}
          editData={education}
        />
      )}
      {deleteModal && (
        <DeletePopup
          bool={deleteModal}
          handleClose={() => {
            setDeleteModal(false);
          }}
          confirmDelete={deleteEducation}
        />
      )}
    </>
  );
};

export default EducationTableRows;
