import { api } from "./api";

class SearchService {
  searchCustom = (token, data) =>
    api.get("object/search/custom/", {
      params: data,
      headers: { Authorization: `Bearer ${token}` },
    });

  searchObject = (id, token) =>
    api.get("object/search/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    });

  updateStatus = (token, data) =>
    api.put("location/update/present", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
}
let searchService = new SearchService();
export default searchService;
