import { api } from "./api";

class RoleService {
	getRoleRequests = (token) =>
		api.get(
			"users/requests/",

			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);
	deleteRequest = (id, token) =>
		api.delete("users/requests/" + id, {
			headers: { Authorization: `Bearer ${token}` },
		});
	addRequest = (token, data) =>
		api.post("users/role/", data, {
			headers: { Authorization: `Bearer ${token}` },
		});
}
let roleService = new RoleService();
export default roleService;
