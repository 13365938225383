import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { useState } from "react";
import moment from "moment";
import searchService from "../../Services/searchService";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

export default function ChangeStatusPopup({
  bool,
  setbool,
  buttonName,
  SERVICEOBJECTID,
  setButtonName,
  email,
  setResult,
  setResetArray,
  result,
}) {
  const [cookies] = useCookies(["JWTtoken"]);
  const [comment, setComment] = useState("");
  const [loading, setloading] = useState(false);

  const handleClose = () => {
    setbool(false);
    setComment("");
  };

  const changeStatus = () => {
    setloading(true);
    const date = new Date();

    searchService
      .updateStatus(cookies.JWTtoken, {
        "CF_IB-0140": moment(date).format("YYYY-MM-DD"),
        "CF_IB-0150": buttonName
          ? buttonName + " " + email + " " + comment
          : "",
        SERVICEOBJECTID: SERVICEOBJECTID,
      })
      .then((data) => {
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        handleClose();
        let arr = result?.map((obj) => {
          if (obj.SERVICEOBJECTID === SERVICEOBJECTID) {
            return {
              ...obj,
              "CF_IB-0140": moment(date).format("YYYY-MM-DD"),
              "CF_IB-0150": buttonName
                ? buttonName + " " + email + " " + comment
                : "",
            };
          }
          return obj;
        });
        setResult(arr);
        setResetArray(arr);
        setloading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setloading(false);
      });
  };

  return (
    <div>
      <LoadingScreen bool={loading} />
      <Dialog
        open={bool}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          sx={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle sx={{ fontWeight: "bold", pb: 0, mb: 0 }}>
            Change Status
          </DialogTitle>
        </Box>

        <DialogContent sx={{ maxWidth: "350px", pt: 0, mt: 0 }}>
          <TextField
            label="Comment"
            value={comment}
            multiline
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Box m={2}>
              <Button onClick={changeStatus}>Change</Button>
            </Box>
            <Box m={2}>
              <Button
                onClick={() => {
                  handleClose();
                  setButtonName("");
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
