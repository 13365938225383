import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import TableRows from "./TableRows";
import { usersHeading } from "../../Common/tables-headings";
export default function TableComponent({ users, getUsers }) {
  return (
    <Box pt={2} ml={2} mr={2} sx={{ marginLeft: "220px" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {usersHeading.map((heading, index) => {
                return <TableCell key={index}>{heading}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <TableRows row={row} key={row.userID} getUsers={getUsers} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
