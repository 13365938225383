import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import locationService from "../../Services/LocationService";

export default function AddLocationPopup({
  bool,
  setBool,
  getLocations,
  mode,
  editData,
}) {
  const [cookies] = useCookies(["JWTtoken"]);
  const [locationDesc, setLocationDesc] = useState("");
  const [locationCustomID, setLocationID] = useState("");

  const handleClose = () => {
    setBool(false);
    setLocationDesc("");
    setLocationID("");
  };

  const createLocation = () => {
    locationService
      .addLocation(cookies.JWTtoken, {
        location: locationDesc,
        locationCustomID,
      })
      .then((data) => {
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        handleClose();
        getLocations();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };
  const editLocation = () => {
    locationService
      .editLocation(cookies.JWTtoken, {
        description: locationDesc,
        locationCustomID,
      })
      .then((data) => {
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        handleClose();
        getLocations();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  useEffect(() => {
    if (editData) {
      setLocationDesc(editData.desc);
      setLocationID(editData.locationId);
    }
  }, [editData]);

  return (
    <div>
      <Dialog
        open={bool}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          sx={{
            width: "200px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle sx={{ fontWeight: "bold" }}>
            {mode === "add" ? "Add Location" : "Edit Location"}
          </DialogTitle>
        </Box>

        <DialogContent sx={{ paddingTop: 0 }}>
          <Box>
            <TextField
              margin="normal"
              label="Location ID"
              value={locationCustomID}
              onChange={(e) => {
                setLocationID(e.target.value);
              }}
            />
            <TextField
              label="Location Description"
              margin="normal"
              value={locationDesc}
              onChange={(e) => {
                setLocationDesc(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Box m={2}>
              <Button
                onClick={(e) => {
                  mode === "add" ? createLocation() : editLocation();
                }}
              >
                {mode === "add" ? "Create" : "Save"}
              </Button>
            </Box>
            <Box m={2}>
              <Button
                onClick={(e) => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
