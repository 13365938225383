import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import gateWayService from "../../Services/GateWayService";

export default function CreateGateWayPopup({ bool, setbool, getgateway }) {
  const [cookies] = useCookies(["JWTtoken"]);
  const [MAC, setMAC] = React.useState("");
  const [Alias, setAlias] = React.useState("");

  const handleClose = () => {
    setbool(false);
    setMAC("");
    setAlias("");
  };

  const createGateWay = () => {
    gateWayService
      .addGateWay(cookies.JWTtoken, { MAC, Alias })
      .then((data) => {
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        handleClose();
        getgateway();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  return (
    <div>
      <Dialog
        open={bool}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          sx={{
            width: "200px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle sx={{ fontWeight: "bold" }}>Add GateWay</DialogTitle>
        </Box>

        <DialogContent>
          <Box>
            <TextField
              label="MAC"
              value={MAC}
              onChange={(e) => {
                setMAC(e.target.value);
              }}
            />

            <TextField
              label="Alias"
              value={Alias}
              onChange={(e) => {
                setAlias(e.target.value);
              }}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Box m={2}>
              <Button
                onClick={(e) => {
                  createGateWay();
                }}
                variant="contained"
              >
                Create
              </Button>
            </Box>
            <Box m={2}>
              <Button
                onClick={(e) => {
                  handleClose();
                }}
                variant="contained"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
