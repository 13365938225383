/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Box } from "@mui/material";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import declarationService from "../../Services/DeclarationService";
import DeclarationTable from "./components/declarationTable/DeclarationTable";
import { MenuItem, FormControl, Typography, Select } from "@mui/material";
import userService from "../../Services/UserService";
import { statuses } from "./declarationUtils";

export default function Declaration() {
  const [cookies] = useCookies(["JWTtoken"]);
  const [loading, setLoading] = useState(false);
  const [declarations, setDeclarations] = useState([]);
  const [declarationsCopy, setDeclarationsCopy] = useState([]);
  const [filterValue, setFilterValue] = useState("0");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("ALL");

  const fetchData = async () => {
    setLoading(true);

    try {
      const [declarationsResponse, usersResponse] = await Promise.all([
        declarationService.getDeclarations(cookies.JWTtoken),
        userService.GetUsers(cookies.JWTtoken),
      ]);

      const declarationsData = declarationsResponse.data.filter(
        (data) => data.approved === 0
      );
      setDeclarations(declarationsData);
      setDeclarationsCopy(declarationsResponse.data);

      setUsers(usersResponse.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      if (error.response && error.response.data === "Forbidden") {
        window.location.href = "/";
      } else {
        console.error("Error fetching data:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const commonUsers = users.filter((user) =>
    declarationsCopy.some((declaration) => declaration.userid === user.userID)
  );
  const usersForFilter = [...new Set(commonUsers)];

  const handleFilterChange = (statusValue, userValue) => {
    let temp = declarationsCopy;

    const filteredDeclarations = temp.filter((dec) => {
      const statusFilter =
        statusValue === "ALL" || dec.approved === parseInt(statusValue);
      const userFilter = userValue === "ALL" || dec.userid === userValue;
      return statusFilter && userFilter;
    });

    setDeclarations(filteredDeclarations);
    setFilterValue(statusValue);
    setSelectedUser(userValue);
  };

  const handleChange = (e) => {
    const statusValue = e.target.value;
    const userValue = selectedUser;
    handleFilterChange(statusValue, userValue);
  };

  const handleUserChange = (e) => {
    const userValue = e.target.value;
    const statusValue = filterValue;
    handleFilterChange(statusValue, userValue);
  };

  return (
    <>
      <LoadingScreen bool={loading} />
      <Box pt={10} ml={2} mr={2} sx={{ marginLeft: "220px" }}>
        <Box sx={{ display: "flex", alignItems: "end" }}>
          <Box sx={{ display: "flex", alignItems: "end", marginRight: "10px" }}>
            <Typography
              mr={2}
              color="primary"
              sx={{ fontWeight: "bold", fontSize: "22px" }}
            >
              Select Status
            </Typography>
            <FormControl sx={{ width: "150px" }}>
              <Select onChange={handleChange} value={filterValue}>
                {statuses.map((status) => {
                  return (
                    <MenuItem key={status.value} value={status.value}>
                      {status.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", alignItems: "end" }}>
            <Typography
              mr={2}
              color="primary"
              sx={{ fontWeight: "bold", fontSize: "22px" }}
            >
              Select User
            </Typography>
            <FormControl sx={{ width: "150px" }}>
              <Select onChange={handleUserChange} value={selectedUser}>
                <MenuItem value={"ALL"}>ALL</MenuItem>
                {usersForFilter.map((user) => {
                  return (
                    <MenuItem key={user.userID} value={user.userID}>
                      {user.firstname + " " + user.lastname}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box mt={2} sx={{ width: "100%" }}>
          <DeclarationTable
            users={users}
            declarations={declarations}
            getDeclarations={fetchData}
          />
        </Box>
      </Box>
    </>
  );
}
