import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { Box, Button, Typography } from "@mui/material";

const ErrorModal = ({ open, onClose, text }) => {
  return (
    <ModalWrapper open={open} onClose={onClose}>
      <Box sx={{textAlign:"center"}}>
        <Typography color="red">{text}</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "right" }}>
       
        <Box m={2}>
          <Button onClick={onClose}>Close</Button>
        </Box>
      </Box>{" "}
    </ModalWrapper>
  );
};

export default ErrorModal;
