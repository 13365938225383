import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import SearchDetailRow from "./SearchDetailRow";
import { useState } from "react";
import { searchDetailTableHeading } from "../../Common/tables-headings";

export default function SearchDetailTable({
  result,
  setResult,
  setResetArray,
}) {
  const [visitedRow, setVisitedRow] = useState([]);
  var tempArray = visitedRow;
  const addToVisited = (id) => {
    tempArray.push(id);
    setVisitedRow([...tempArray]);
  };

  return (
    <Box ml={2} mr={2} sx={{ marginLeft: "220px" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {searchDetailTableHeading.map((heading, index) => {
                return <TableCell key={index}>{heading}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {result?.map((row) => {
              return (
                <SearchDetailRow
                  row={row}
                  key={row.SERVICEOBJECTID}
                  result={result}
                  addToVisited={addToVisited}
                  visitedRow={visitedRow}
                  setResult={setResult}
                  setResetArray={setResetArray}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
