import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { userEducations } from "../../../../Common/tables-headings";
import UserEducationsRows from "./UserEducationsRows";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";

export const sortingHeading = [
  "Deadline",
  "Afgerond",
];

const UserEducationsTable = ({ educations, getEducations ,  setSortingFn,
  sorting,}) => {
  const handleSorting = (heading, order) => {
    setSortingFn(heading, order);
  };
  
  
  return (
    <Box pt={10} ml={2} mr={2} sx={{ marginLeft: "220px" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
             


{userEducations.map((heading, index) => {
                return (
                  <TableCell key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <span>{heading}</span>
                      {sortingHeading.includes(heading) && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {sorting.some(
                              (criteria) =>
                                criteria.heading === heading &&
                                criteria.order === "asc"
                            ) ? (
                              <CloseIcon
                                onClick={() => {
                                  handleSorting(heading, "");
                                }}
                              />
                            ) : (
                              <ArrowDropUpIcon
                                onClick={() => {
                                  handleSorting(heading, "asc");
                                }}
                              />
                            )}
                            {sorting.some(
                              (criteria) =>
                                criteria.heading === heading &&
                                criteria.order === "dsc"
                            ) ? (
                              <CloseIcon
                                onClick={() => {
                                  handleSorting(heading, "");
                                }}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() => {
                                  handleSorting(heading, "dsc");
                                }}
                              />
                            )}
                          </Box>
                        </>
                      )}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {educations.map((education, index) => {
              return (
                <UserEducationsRows
                  userEducation={education}
                  getEducations={getEducations}
                  key={index}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserEducationsTable;
