/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import tagService from "../../Services/TagsService";
import { Checkbox } from "@mui/material";

export default function EditTagPopup({ bool, setbool, gettags, row }) {
  const [cookies] = useCookies(["JWTtoken"]);
  const [description, setdescription] = React.useState(row.Description);
  const [note, setNote] = React.useState(row.Note);
  const [objectnr, setobjectnr] = React.useState(
    row.ObjectNr ? row.ObjectNr : ""
  );

  const [isFixed, setIsFixed] = React.useState(row.fixed === 1 ? true : false);
  const handleClose = () => {
    setbool(false);
    setobjectnr("");
    setdescription("");
  };
  React.useEffect(() => {
    setobjectnr(row.ObjectNr !== "undefined" ? row.ObjectNr : "");
    setNote(row.Note);
    setdescription(row.Description);
  }, [bool]);

  const EditFunc = () => {
    tagService
      .updateTag(row.TagID, cookies.JWTtoken, {
        description,
        objectnr,
        note,
        fixed: isFixed,
      })
      .then((data) => {
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        handleClose();
        gettags();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const onPriorityChange = () => {
    setIsFixed(!isFixed);
  };

  return (
    <div>
      <Dialog
        open={bool}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          sx={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle sx={{ fontWeight: "bold" }}>Edit Tag</DialogTitle>
        </Box>

        <DialogContent sx={{ maxWidth: "350px", overflow: "hidden" }}>
          <Box>
            <TextField
              label="Object Number"
              value={objectnr}
              onChange={(e) => {
                setobjectnr(e.target.value);
              }}
            />
            <TextField
              label="Description"
              multiline
              value={description}
              onChange={(e) => {
                setdescription(e.target.value);
              }}
            />
            <TextField
              label="Note"
              multiline
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </Box>
          <Box
            m={2}
            sx={{
              display: "flex",
              flex: 1,
              width: "100%",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              Fixed tag
              <Box>
                <Checkbox
                  defaultChecked={isFixed}
                  onClick={onPriorityChange}
                ></Checkbox>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Box m={2}>
                <Button
                  onClick={() => {
                    EditFunc();
                  }}
                  variant="contained"
                >
                  Edit
                </Button>
              </Box>
              <Box m={2}>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  variant="contained"
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
