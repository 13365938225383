import { api } from "./api";

class GateWayService {
	getGateWays = (token) =>
		api.get(
			"gateway/",

			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);
	deleteGateWay = (id, token) =>
		api.delete("gateway/" + id, {
			headers: { Authorization: `Bearer ${token}` },
		});

	updateGateWay = (id, token, data) =>
		api.put("gateway/" + id, data, {
			headers: { Authorization: `Bearer ${token}` },
		});

	addGateWay = (token, data) =>
		api.post("gateway/", data, {
			headers: { Authorization: `Bearer ${token}` },
		});
}
let gateWayService = new GateWayService();
export default gateWayService;
