import React, { useEffect, useState } from "react";
import ModalWrapper from "../../Components/ModalWrapper/ModalWrapper";
import {
  TextField,
  InputLabel,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import educationService from "../../Services/EducationService";
import { useCookies } from "react-cookie";
import moment from "moment";
import { toast } from "react-toastify";

const AssignEducationModal = ({ open, onClose, userId }) => {
  const defaultValues = {
    educationID: "",
    deadline: moment(),
    description: "",
    note: "",
    userID: userId,
  };
  const [cookies] = useCookies(["JWTtoken"]);
  const [educations, setEducations] = useState([]);
  const [error, setError] = useState(false);
  const [assignEducationData, setAssignEducationData] = useState(defaultValues);

  const getEducations = () => {
    const allEducationsPromise = educationService.GetEducations(
      cookies.JWTtoken
    );
    const userEducationsPromise = educationService.GetEducationsByCustomers(
      userId,
      cookies.JWTtoken
    );
    Promise.all([allEducationsPromise, userEducationsPromise])
      .then(([allEducationsResponse, userEducationsResponse]) => {
        const educationIdSet = new Set(
          userEducationsResponse.data.map((item) => item.educationID)
        );
        const filteredEducations = allEducationsResponse.data.filter(
          (education) => !educationIdSet.has(education.educationID)
        );
        setEducations(filteredEducations);
      })
      .catch((error) => {
        console.error("Error fetching educations:", error);
        if (error.response && error.response.data === "Forbidden") {
          window.location.href = "/";
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  useEffect(() => {
    getEducations();
  }, []);

  const onSubmit = () => {
    if (Object.values(assignEducationData).some((value) => !value)) {
      setError(true);
      return;
    }
    setError(false);
    const { deadline, description, educationID, note, userID } =
      assignEducationData;
    const newObject = {
      userID,
      description,
      educationID,
      note,
      deadline: moment(deadline).format("DD-MM-YYYY"),
    };
    educationService
      .assignUserEducation(newObject, cookies.JWTtoken)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          onClose();
        }
      });
  };

  return (
    <ModalWrapper open={open} handleClose={onClose} title="Assign Education">
      <Box mt={2}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Education
          </InputLabel>
          <Select
            sx={{ width: "100%" }}
            label="Education ID"
            value={assignEducationData.educationID}
            onChange={(e) => {
              setAssignEducationData({
                ...assignEducationData,
                educationID: e.target.value,
              });
            }}
          >
            {educations?.map((education, index) => {
              return (
                <MenuItem key={index} value={education.educationID}>
                  {education.description}
                </MenuItem>
              );
            })}
            {!educations.length && (
              <MenuItem disabled>No Education Left to assign</MenuItem>
            )}
          </Select>
        </FormControl>
        <TextField
          type="date"
          value={moment(assignEducationData.deadline).format("YYYY-MM-DD")}
          onChange={(e) => {
            setAssignEducationData({
              ...assignEducationData,
              deadline: e.target.value,
            });
          }}
        />
        <TextField
          margin="normal"
          variant="outlined"
          label="Description"
          multiline
          minRows={3}
          value={assignEducationData.description}
          onChange={(e) => {
            setAssignEducationData({
              ...assignEducationData,
              description: e.target.value,
            });
          }}
        />
        <TextField
          margin="normal"
          variant="outlined"
          label="Note"
          multiline
          minRows={3}
          value={assignEducationData.note}
          onChange={(e) => {
            setAssignEducationData({
              ...assignEducationData,
              note: e.target.value,
            });
          }}
        />
      </Box>
      {error && (
        <Typography fontSize="small" color="red">
          Please fill all fields
        </Typography>
      )}
      <Box sx={{ display: "flex", justifyContent: "right" }}>
        <Box m={2}>
          <Button onClick={onSubmit}>Assign</Button>
        </Box>
        <Box m={2}>
          <Button onClick={onClose}>Close</Button>
        </Box>
      </Box>{" "}
    </ModalWrapper>
  );
};

export default AssignEducationModal;
