/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, IconButton, Divider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCookies } from "react-cookie";
import customerService from "../../Services/CustomerService";
import DeletePopup from "../../Components/Popups/DeletePopup";
export default function SelectComponent({ data, userID, setcheck, getUsers }) {
  const [cookies] = useCookies(["JWTtoken"]);
  const [customers, setcustomers] = useState();
  const [Alias, setAlias] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);

  const handleClose = () => {
    setDeletePopup(!deletePopup);
  };

  useEffect(() => {
    setcheck?.(true);
    customerService.GetCustomers(cookies.JWTtoken).then((data) => {
      setcustomers(data.data);
      setcheck?.(false);
    });
  }, []);

  const unBindCustomer = () => {
    customerService
      .unBindCustomer(cookies.JWTtoken, { UserID: userID, CustomerID: data })
      .then((data) => {
        getUsers();
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setAlias(
      customers?.filter((mac) => {
        return mac.CustomerID === data;
      })
    );
  }, [customers]);

  return (
    <>
      <DeletePopup
        confirmDelete={unBindCustomer}
        bool={deletePopup}
        handleClose={handleClose}
      />
      {Alias ? (
        <>
          {data && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box m={0.5}>{data + " | " + Alias[0]?.Alias}</Box>
              <IconButton
                onClick={(e) => {
                  setDeletePopup(true);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size="1rem" color="success" />
        </Box>
      )}
      {!data && <> No Customers</>}
      <Divider />
      <br />
    </>
  );
}
