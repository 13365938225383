import React, { useState } from "react";
import ModalWrapper from "../../../../Components/ModalWrapper/ModalWrapper";
import { TextField, Box, Button } from "@mui/material";

const EditModal = ({ open, onClose, description, onSuccess }) => {
  const [descriptionField, setDescriptionField] = useState(description);

  return (
    <ModalWrapper open={open} onClose={onClose} title={`Edit Description`}>
      <Box mt={1} sx={{ width: "100%" }}>
        <TextField
          margin="normal"
          variant="outlined"
          label="Description"
          multiline
          minRows={5}
          value={descriptionField}
          onChange={(e) => {
            setDescriptionField(e.target.value);
          }}
        />
      </Box>
      <Box mt={1} sx={{ display: "flex", justifyContent: "right" }}>
        <Box m={2}>
          <Button onClick={() => onSuccess(descriptionField)}>Edit</Button>
        </Box>
        <Box m={2}>
          <Button
            onClick={(e) => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default EditModal;
