import React from "react";
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@mui/material";
import { educationsHeading } from "../../../Common/tables-headings";
import EducationTableRows from "./EducationTableRows";

const EducationTable = ({ getEducations, educations }) => {
  return (
    <Box pt={10} ml={2} mr={2} sx={{ marginLeft: "220px" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {educationsHeading.map((heading, index) => {
                return <TableCell key={index}>{heading}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {educations.map((education) => {
              return (
                <EducationTableRows
                  key={education.educationID}
                  education={education}
                  getEducations={getEducations}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EducationTable;
