import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { useEffect, useState } from "react";
import declarationService from "../../../../../../Services/DeclarationService";
import { useCookies } from "react-cookie";
import LoadingScreen from "../../../../../../Components/LoadingScreen/LoadingScreen";
import moment from "moment";
import {
  declarationStatus,
  declarationStatusColor,
  currency,
} from "../../../../declarationUtils";

const DeclarationDetailPopup = ({ open, handleClose, declarationItem }) => {
  const [cookies] = useCookies(["JWTtoken"]);
  const [loading, setLoading] = useState(false);
  const [blobUrl, setBlobUrl] = useState();
  const getSingleBlob = () => {
    if (!declarationItem?.receipts) return;
    setLoading(true);
    const { receiptid } = declarationItem?.receipts[0];
    declarationService
      .getReceiptBlob(cookies.JWTtoken, receiptid)
      .then((data) => {
        setLoading(false);
        setBlobUrl(data.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (error.response.data === "Forbidden") {
          window.location.href = "/";
        }
      });
  };
  useEffect(getSingleBlob, []);

  return (
    <div>
      <Dialog
        open={open}
        disableScrollLock
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <LoadingScreen bool={loading} />
        <Box sx={{ minWidth: "500px" }}>
          <DialogContent>
            {declarationItem.receipts && declarationItem.iscosts ? (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5">{declarationItem.note}</Typography>
                  <Box
                    sx={{
                      color: declarationStatusColor(declarationItem.approved),
                      fontWeight: "bold",
                    }}
                  >
                    {declarationStatus(declarationItem.approved)}
                  </Box>
                </Box>
                <Typography gutterBottom variant="body2" color="text.secondary">
                  Declaration Date:{" "}
                  {moment(declarationItem.declarationdate).format(
                    "DD/MM/YYYY HH:MM"
                  )}
                </Typography>
                {declarationItem?.receipts?.map((rec) => {
                  return (
                    <span key={rec.receiptid}>
                      <div className="d-flex">
                        <span className="col-sm-8">Total Without Tax</span>
                        <span className="col-sm-4">
                          {rec?.grandtotalwithouttax}{" "}
                          {currency(declarationItem.currency)}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="col-sm-8">Tax:</span>
                        <span className="col-sm-4">
                          {rec?.taxamount} {currency(declarationItem.currency)}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="col-sm-8">Grand Total</span>
                        <span className="col-sm-4">
                          {rec?.grandtotal} {currency(declarationItem.currency)}
                        </span>
                      </div>
                      <Typography variant="h6" component="div">
                        Receipt Items
                      </Typography>
                      {rec.receiptItems?.map((item) => {
                        return (
                          <div className="m-1" key={item.itemid}>
                            <Card sx={{ width: "500px" }}>
                              <CardContent>
                                <Typography
                                  gutterBottom
                                  variant="h7"
                                  component="div"
                                  fontWeight="bold"
                                >
                                  {item.note}
                                </Typography>
                                <div className="d-flex">
                                  <span className="col-sm-4">Item Name</span>
                                  <span className="col-sm-8">
                                    {item.itemname}
                                  </span>
                                </div>
                                <div className="d-flex">
                                  <span className="col-sm-4">Quantity</span>
                                  <span className="col-sm-8">
                                    {item.quantity}
                                  </span>
                                </div>
                                <div className="d-flex">
                                  <span className="col-sm-4">Price</span>
                                  <span className="col-sm-8">
                                    {item.price}{" "}
                                    {currency(declarationItem.currency)}
                                  </span>
                                </div>
                              </CardContent>
                            </Card>
                          </div>
                        );
                      })}
                    </span>
                  );
                })}

                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ display: "flex" }}
                ></Typography>
                {blobUrl && (
                  <>
                  <Box sx={{ height: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={`data:image/jpeg;base64,${blobUrl}`} alt="Uploaded" style={{ width: "400px", height: "auto" }} />
                  </Box>
                  </>
                )}
              </Box>
            ) : (
              <Box>
                <Typography variant="h5">{declarationItem.note}</Typography>
                <Typography gutterBottom variant="body2" color="text.secondary">
                  Declaration Date:{" "}
                  {moment(declarationItem.declarationdate).format(
                    "DD/MM/YYYY HH:MM"
                  )}
                </Typography>
                <div className="d-flex">
                  <span className="col-sm-8">Amount</span>
                  <span className="col-sm-4">{declarationItem.value} KM</span>
                </div>
              </Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "right" }}>
              <Box m={2}>
                <Button
                  onClick={(e) => {
                    handleClose();
                  }}
                  variant="contained"
                >
                  Close
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
};

export default DeclarationDetailPopup;
