import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import tagService from "../../Services/TagsService";
import { Checkbox } from "@mui/material";

export default function CreateTagPopup({ bool, setbool, gettags }) {
	const [cookies] = useCookies(["JWTtoken"]);
	const [MAC, setMAC] = React.useState("");
	const [description, setdescription] = React.useState("");
	const [isFixed, setIsFixed] = React.useState(false);

	const handleClose = () => {
		setbool(false);
		setMAC("");
		setdescription("");
		setIsFixed(false);
	};

	const createTag = () => {
		tagService
			.addTag(cookies.JWTtoken, { MAC, description, fixed: isFixed })
			.then((data) => {
				toast.success(data.data.message, {
					position: toast.POSITION.BOTTOM_LEFT,
				});
				handleClose();
				gettags();
			})
			.catch((error) => {
				console.log(error);
				toast.error(error.response.data.message, {
					position: toast.POSITION.BOTTOM_LEFT,
				});
			});
	};

	const onPriorityChange = () => {
		setIsFixed(!isFixed);
	};

	return (
		<div>
			<Dialog
				open={bool}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<Box
					sx={{
						height: "40px",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<DialogTitle sx={{ fontWeight: "bold", pb: 0, mb: 0 }}>
						Add Tag
					</DialogTitle>
				</Box>

				<DialogContent sx={{ maxWidth: "350px", pt: 0, mt: 0 }}>
					<Box>
						<TextField
							label="MAC"
							value={MAC}
							onChange={(e) => {
								setMAC(e.target.value);
							}}
						/>

						<TextField
							label="Description"
							value={description}
							onChange={(e) => {
								setdescription(e.target.value);
							}}
						/>
					</Box>
					<Box
						m={2}
						sx={{
							display: "flex",
							flex: 1,
							width: "100%",
						}}
					>
						<Box
							sx={{
								flexGrow: 1,
							}}
						>
							Fixed tag
							<Box>
								<Checkbox onClick={onPriorityChange}></Checkbox>
							</Box>
						</Box>
						<Box sx={{ display: "flex", justifyContent: "right" }}>
							<Box m={2}>
								<Button
									onClick={(e) => {
										createTag();
									}}
									variant="contained"
								>
									Create
								</Button>
							</Box>
							<Box m={2}>
								<Button
									onClick={(e) => {
										handleClose();
									}}
									variant="contained"
								>
									Cancel
								</Button>
							</Box>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}
