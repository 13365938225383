import React, { useState } from "react";
import { TableRow, TableCell, TextField, Box } from "@mui/material";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import EditModal from "../editModal/EditModal";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import progressConversationService from "../../../../Services/ProgressConversations";
import { debounce } from "lodash";
import LoadingScreen from "../../../../Components/LoadingScreen/LoadingScreen";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import educationService from "../../../../Services/EducationService";
import ErrorModal from "../../../../Components/Popups/ErrorModal";
import { useAppContext } from "../../../../context/AppContext";

const ProgressConversationRow = ({
  getProgressConversations,
  conversation,
}) => {
  const {
    appState: { role },
  } = useAppContext();
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const [editModal, setEditModal] = useState(null);
  const [error, setError] = useState(false);
  const [bool, setBool] = useState(false);

  const navigation = () => {
    if (role && role > 3) {
      setBool(true);
      educationService
        .GetEducationsByCustomers(conversation.userID, cookies.JWTtoken)
        .then((data) => {
          if (data.data.length) {
            navigate(`/user-education/${conversation.userID}`);
          } else {
            setError(true);
          }
          setBool(false);
        })
        .catch(() => {
          setBool(false);
        });
    }
  };

  const dateSetter = async (date, dateNumber) => {
    setBool(true);
    const editData = {
      progressID: conversation.progressID,
      date: moment(date).format("DD-MM-YYYY"),
      dateNumber,
    };
    const res = await progressConversationService.editPCDate(
      editData,
      cookies.JWTtoken
    );
    if (res.data) {
      toast.success(res.data, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    setBool(false);
  };

  const debouncedHandleChanges = debounce((date, dateNumber) => {
    dateSetter(date, dateNumber);
  }, 3000);

  return (
    <>
      <LoadingScreen bool={bool} />
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell onClick={navigation}>{conversation.userID}</TableCell>
        <TableCell
        sx={{cursor:"pointer", color:"blue", textDecoration:"underline"}}
          onClick={navigation}
        >{`${conversation.userFirstName} ${conversation.userLastName}`}</TableCell>
        <TableCell>
          <TextField
            type="date"
            onChange={(e) => debouncedHandleChanges(e.target.value, 1)}
          />
        </TableCell>
        <TableCell>
          <TextField
            type="date"
            onChange={(e) => debouncedHandleChanges(e.target.value, 2)}
          />
        </TableCell>
        <TableCell>
          <TextField
            type="date"
            onChange={(e) => debouncedHandleChanges(e.target.value, 3)}
          />
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                minWidth: "130px",
                maxWidth: "130px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {conversation.progressConversation
                ? `${conversation.progressConversation}`
                : "-"}
            </Box>

            <IconButton
              onClick={() => {
                setEditModal("ProgressReview");
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                minWidth: "130px",
                maxWidth: "130px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {conversation.managerFirstName
                ? `${conversation.managerFirstName} ${conversation.managerLastName}`
                : "-"}
            </Box>

            <IconButton
              onClick={() => {
                setEditModal("manager");
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {editModal && (
        <EditModal
          open={!!editModal}
          progressID={conversation.progressID}
          editData={{
            review: conversation.progressConversation,
            manager: conversation.managerID,
          }}
          onClose={() => {
            setEditModal(null);
          }}
          type={editModal}
          onSuccess={getProgressConversations}
        />
      )}
      {error && (
        <ErrorModal
          open={error}
          onClose={() => setError(false)}
          text="This user has no educations. Choose another user or assign eductions to this user."
        />
      )}
    </>
  );
};

export default ProgressConversationRow;
