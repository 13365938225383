import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Button, Box } from "@mui/material";
import { useState, useEffect } from "react";
import ChangeStatusPopup from "../../Components/Popups/ChangeStatusPopup";
import searchService from "../../Services/searchService";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import userService from "../../Services/UserService";
import moment from "moment";
import StatusDetailPopup from "../../Components/Popups/StatusDetailPopup";

export default function SearchDetailRow({
  row,
  addToVisited,
  visitedRow,
  setResult,
  result,
  setResetArray,
}) {
  const [buttonName, setButtonName] = useState("");
  const [statusPopup, setStatusPopup] = useState(false);
  const [detailsPopup, setDetailsPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState(false);
  const [cookies] = useCookies(["JWTtoken"]);

  const undoStatus = () => {
    setloading(true);
    searchService
      .updateStatus(cookies.JWTtoken, {
        "CF_IB-0140": "",
        "CF_IB-0150": "",
        SERVICEOBJECTID: row.SERVICEOBJECTID,
      })
      .then((data) => {
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        let arr = result?.map((obj) => {
          if (obj.SERVICEOBJECTID === row.SERVICEOBJECTID) {
            return {
              ...obj,
              "CF_IB-0140": "",
              "CF_IB-0150": "",
            };
          }
          return obj;
        });
        setResult(arr);
        setResetArray(arr);
        setloading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setloading(false);
      });
  };
  const presentStatus = (e) => {
    const date = new Date();
    setloading(true);
    searchService
      .updateStatus(cookies.JWTtoken, {
        "CF_IB-0140": moment(date).format("YYYY-MM-DD"),
        "CF_IB-0150": e.target.name + " " + email,
        SERVICEOBJECTID: row.SERVICEOBJECTID,
      })
      .then((data) => {
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setloading(false);
        let arr = result?.map((obj) => {
          if (obj.SERVICEOBJECTID === row.SERVICEOBJECTID) {
            return {
              ...obj,
              "CF_IB-0140": moment(date).format("YYYY-MM-DD"),
              "CF_IB-0150": e.target.name + " " + email,
            };
          }
          return obj;
        });
        setResult(arr);
        setResetArray(arr);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setloading(false);
      });
  };

  const getRole = () => {
    if (cookies.JWTtoken && cookies.JWTtoken !== "undefined") {
      const { email } = userService.GetRole(cookies?.JWTtoken);
      setEmail(email);
    }
  };
  useEffect(() => {
    getRole();
  });

  const detailsShow = () => {
    setDetailsPopup(true);
  };

  return (
    <>
      <LoadingScreen bool={loading} />
      <TableRow
        sx={{
          cursor: "pointer",
          "&:last-child td, &:last-child th": { border: 0 },
          backgroundColor: visitedRow.includes(row.SERVICEOBJECTID)
            ? "#eeeeee"
            : "white",
        }}
      >
        <TableCell onClick={detailsShow}>
          {row?.SERVICEOBJECTID ? row?.SERVICEOBJECTID : "N/A"}
        </TableCell>
        <TableCell onClick={detailsShow}>
          {row?.CUSTOMEROBJECT ? row?.CUSTOMEROBJECT : "N/A"}
        </TableCell>
        <TableCell onClick={detailsShow}>
          {row?.OBJECTTYPEDESC ? row?.OBJECTTYPEDESC : "N/A"}
        </TableCell>
        <TableCell onClick={detailsShow}>
          {row?.SERIALNUMBER ? row?.SERIALNUMBER : "N/A"}
        </TableCell>
        <TableCell onClick={detailsShow}>
          {row?.DEPARTMENT ? row?.DEPARTMENT : "N/A"}
        </TableCell>
        <TableCell onClick={detailsShow}>
          {row["CF_IB-0020"] ? row["CF_IB-0020"] : "N/A"}
        </TableCell>
        <TableCell onClick={detailsShow}>
          {row?.STATUSDESC ? row?.STATUSDESC : "N/A"}
        </TableCell>
        <TableCell onClick={detailsShow}>
          {row?.MAINTENANCELASTMONTH
            ? moment(row?.MAINTENANCELASTMONTH).format("MM-YYYY")
            : "N/A"}
        </TableCell>
        <TableCell onClick={detailsShow}>
          {row?.MAINTENANCENEXTTMONTH ? row?.MAINTENANCENEXTTMONTH : "N/A"}
        </TableCell>

        <TableCell onClick={detailsShow}>
          {row?.MAINTENANCEFREQUENCY ? row?.MAINTENANCEFREQUENCY : "N/A"}
        </TableCell>

        <TableCell sx={{ zIndex: 1000 }}>
          <Box sx={{ display: "flex" }}>
            {buttonName !== row.SERVICEOBJECTID ? (
              <>
                <Button
                  sx={{ margin: "5px" }}
                  name="Aanwezig"
                  onClick={(e) => {
                    setButtonName(row.SERVICEOBJECTID);
                    addToVisited(row.SERVICEOBJECTID);
                    presentStatus(e);
                  }}
                >
                  Present
                </Button>
                <Button
                  sx={{ margin: "5px" }}
                  name="Niet aanwezig"
                  onClick={(e) => {
                    setButtonName(row.SERVICEOBJECTID);
                    addToVisited(row.SERVICEOBJECTID);
                    setStatusPopup(true);
                  }}
                >
                  Not Present
                </Button>
              </>
            ) : (
              <Box>
                <Button
                  sx={{ margin: "5px" }}
                  onClick={(e) => {
                    setButtonName("");
                    undoStatus();
                  }}
                >
                  Undo
                </Button>
              </Box>
            )}
          </Box>
        </TableCell>
      </TableRow>
      {statusPopup && (
        <ChangeStatusPopup
          bool={statusPopup}
          setbool={setStatusPopup}
          email={email}
          SERVICEOBJECTID={row.SERVICEOBJECTID}
          buttonName={buttonName}
          setButtonName={setButtonName}
          result={result}
          setResult={setResult}
          setResetArray={setResetArray}
        />
      )}
      {detailsPopup && (
        <StatusDetailPopup
          bool={detailsPopup}
          setbool={setDetailsPopup}
          row={row}
        />
      )}
    </>
  );
}
