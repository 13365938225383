import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import moment from "moment";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCookies } from "react-cookie";
import gateWayService from "../../Services/GateWayService";
import EditGateWayPopup from "../../Components/Popups/EditGateWayPopup";
import DeletePopup from "../../Components/Popups/DeletePopup";
export default function GateWayTableRows({ row, getgateway }) {
  const [cookies] = useCookies(["JWTtoken"]);
  const [gatewayedit, setgatewayedit] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  const handleClose = () => {
    setDeletePopup(!deletePopup);
  };

  const deleteGateWay = () => {
    gateWayService
      .deleteGateWay(row.GatewayID, cookies.JWTtoken)
      .then((data) => {
        getgateway();
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <EditGateWayPopup
        bool={gatewayedit}
        setbool={setgatewayedit}
        getgateway={getgateway}
        row={row}
      />
      <DeletePopup
        confirmDelete={deleteGateWay}
        bool={deletePopup}
        handleClose={handleClose}
      />

      <TableRow
        key={row.GatewayID}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>{row.GatewayID}</TableCell>
        <TableCell>{row.MAC}</TableCell>
        <TableCell>{row.Alias}</TableCell>
        <TableCell>
          <b>{moment(row.Date_last_active).format("DD MMMM YYYY HH:mm")}</b>
        </TableCell>
        <TableCell>
          {moment(row.Date_added).format("DD MMMM YYYY HH:mm")}
        </TableCell>
        <TableCell sx={{ display: "flex" }}>
          <IconButton
            onClick={() => {
              setgatewayedit(true);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeletePopup(true);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
