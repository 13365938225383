import { api } from "./api";

class ProgressConversationService {
  GetProgressConversations = (token) =>
    api.get("progressConversations/", {
      headers: { Authorization: `Bearer ${token}` },
    });

  getManagers = (token) =>
    api.get("progressConversations/getManagers", {
      headers: { Authorization: `Bearer ${token}` },
    });

  deleteProgressConversation = (id, token) =>
    api.delete(`progressConversations/delete/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

  editPCReview = (data, token) =>
    api.put("progressConversations/setReview", data, {
      headers: { Authorization: `Bearer ${token}` },
    });

  editPCManager = (data, token) =>
    api.put("progressConversations/setManager", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  editPCDate = (data, token) =>
    api.put("progressConversations/setDate", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
}
let progressConversationService = new ProgressConversationService();
export default progressConversationService;
