import { createContext, useContext, useState } from "react";
import { useCookies } from "react-cookie";

const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {
  const [cookies] = useCookies(["JWTtoken"]);
  const isLoggedIn = cookies.JWTtoken && cookies.JWTtoken !== "undefined";

  const initialValues = {
    loading: false,
    isLoggedIn,
    role: null,
  };

  const [appState, setAppState] = useState(initialValues);

  const updateAppState = (newState) => {
    setAppState((prev) => ({ ...prev, ...newState }));
  };

  return (
    <AppContext.Provider
      value={{
        appState,
        updateAppState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const appContext = useContext(AppContext);

  if (!appContext) {
    throw new Error("Please provide values to app provider");
  }

  return appContext;
};
