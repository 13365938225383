import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Box } from "@mui/material";
import TagsTableRows from "./TagsTableRows";
import { tagsHeading } from "../../Common/tables-headings";
import { sortingTagsHeading } from "./Tags";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";

export default function TagsTableComponent({
  tags,
  gettags,
  locationFilter,
  setSortingFn,
  sorting,
}) {
  const handleSorting = (heading, order) => {
    setSortingFn(heading, order);
  };
  return (
    <Box pt={1} ml={2} mr={2} sx={{ marginLeft: "220px" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tagsHeading.map((heading, index) => {
                return (
                  <TableCell key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <span>{heading}</span>
                      {sortingTagsHeading.includes(heading) && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {sorting.some(
                              (criteria) =>
                                criteria.heading === heading &&
                                criteria.order === "asc"
                            ) ? (
                              <CloseIcon
                                onClick={() => {
                                  handleSorting(heading, "");
                                }}
                              />
                            ) : (
                              <ArrowDropUpIcon
                                onClick={() => {
                                  handleSorting(heading, "asc");
                                }}
                              />
                            )}
                            {sorting.some(
                              (criteria) =>
                                criteria.heading === heading &&
                                criteria.order === "dsc"
                            ) ? (
                              <CloseIcon
                                onClick={() => {
                                  handleSorting(heading, "");
                                }}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() => {
                                  handleSorting(heading, "dsc");
                                }}
                              />
                            )}
                          </Box>
                        </>
                      )}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tags?.map((row) => {
              return (
                <TagsTableRows
                  row={row}
                  key={row.TagID}
                  gettags={gettags}
                  locationFilter={locationFilter}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
