import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppRouter from "./Components/AppRouter/AppRouter";
import { AppContextProvider } from "./context/AppContext";

function App() {
  return (
    <CookiesProvider>
      <AppContextProvider>
        <Router>
          <ToastContainer />
          <AppRouter />
        </Router>
      </AppContextProvider>
    </CookiesProvider>
  );
}

export default App;
