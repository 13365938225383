import React from "react";
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@mui/material";
import { progressConversationHeading } from "../../../../Common/tables-headings";
import ProgressConversationRow from "./ProgressConversationRow";

const ProgressConversationTable = ({
  getProgressConversations,
  progressConversations,
}) => {
  return (
    <Box pt={10} ml={2} mr={2} sx={{ marginLeft: "220px" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {progressConversationHeading.map((heading, index) => {
                return <TableCell key={index}>{heading}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {progressConversations.map((conversation, index) => {
              return (
                <ProgressConversationRow
                  conversation={conversation}
                  getProgressConversations={getProgressConversations}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProgressConversationTable;
