import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export default function DeletePopup({ bool, handleClose, confirmDelete }) {
  return (
    <div>
      <Dialog
        open={bool}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box>
            <Typography>Are you sure you want to remove?</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Box m={2}>
              <Button onClick={confirmDelete}>Confirm</Button>
            </Box>
            <Box m={2}>
              <Button onClick={handleClose}>Cancel</Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
