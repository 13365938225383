/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import roleService from "../../Services/roleService";
import TableComponent from "./TableComponent";
import { useCookies } from "react-cookie";
import { Box } from "@mui/material";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";

export default function RoleRequest() {
  const [cookies] = useCookies(["JWTtoken"]);
  const [loading, setloading] = React.useState(false);
  const [requests, setrequests] = React.useState([]);

  const getrequests = () => {
    setloading(true);
    roleService
      .getRoleRequests(cookies.JWTtoken)
      .then((data) => {
        setloading(false);
        setrequests(data.data.requests);
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
        setrequests([]);
      });
  };
  React.useEffect(() => {
    getrequests();
  }, []);
  return (
    <>
      <LoadingScreen bool={loading} />
      <Box sx={{ width: "100%" }}>
        <TableComponent requests={requests} getrequests={getrequests} />
      </Box>
    </>
  );
}
