import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import SearchRow from "./SearchRow";
import { searchTableHeading } from "../../Common/tables-headings";

export default function SearchTable({ result, search, error }) {
  return (
    <Box sx={{ marginLeft: "220px" }} mr={2}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {searchTableHeading.map((heading, index) => {
                return <TableCell key={index}>{heading}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {result.length > 0 ? (
              <>
                {result.map((row) => {
                  return (
                    <SearchRow row={row} key={row.RELID} search={search} />
                  );
                })}
              </>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                {error ? (
                  <>
                    <h1>No Result Found</h1>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
