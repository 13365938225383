import SearchTable from "./SearchTable";
import { useCookies } from "react-cookie";
import { Box, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import searchService from "./../../Services/searchService";
import { useLocation } from "react-router-dom";
const SearchPage = () => {
  const location = useLocation();
  const { state } = location;
  const [cookies] = useCookies(["JWTtoken"]);
  const [loading, setloading] = useState(false);
  const [result, setResult] = useState([]);
  const [error, setError] = useState(false);

  const [search, setSearch] = useState(
    state?.search !== 0 ? state?.search : "" | ""
  );

  const callSearch = (key) => {
    setResult([]);

    setloading(true);
    searchService
      .searchCustom(cookies.JWTtoken, {
        search: key,
      })
      .then((data) => {
        setloading(false);
        if (data.data.length > 0) {
          setResult(data.data);
        } else {
          setResult([]);
        }
        setError(true);
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
      });
  };

  return (
    <>
      <Box
        pt={10}
        ml={2}
        mr={2}
        sx={{
          marginLeft: "220px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "50%",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
          }}
        >
          <Box sx={{ width: "50px" }}>
            {loading && <CircularProgress size="30px" />}
          </Box>
          <TextField
            id="filled-search"
            label="Search field"
            type="search"
            value={search}
            variant="standard"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                callSearch(search);
              }
            }}
          />
        </Box>
      </Box>

      <Box sx={{ width: "100%" }} mt={2}>
        <SearchTable result={result} search={search} error={error} />
      </Box>
    </>
  );
};

export default SearchPage;
