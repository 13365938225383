import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";

function PagesLayout() {
  return (
    <div>
      <Topbar />
      <Sidebar />
      <Box>
        <Outlet />
      </Box>
    </div>
  );
}

export default PagesLayout;
