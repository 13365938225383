import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import declarationService from "../../../../Services/DeclarationService";
import LoadingScreen from "../../../../Components/LoadingScreen/LoadingScreen";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, Button } from "@mui/material";
import moment from "moment";
import DeclarationCard from "./components/DeclarationCard/DeclarationCard";
import DeclarationDetailPopup from "./components/DeclarationDetailPopup/DeclarationDetailCard";
import userService from "../../../../Services/UserService";
import {
  declarationStatus,
  declarationStatusColor,
} from "../../declarationUtils";

const SingleUserDeclaration = () => {
  const { id } = useParams();
  const [cookies] = useCookies(["JWTtoken"]);
  const [loading, setLoading] = useState(false);
  const [singleDeclarations, setSingleDeclarations] = useState();
  const [declarationItem, setDeclarationItem] = useState();
  const [users, setUsers] = useState([]);
  const [declarationDetailsModal, setDeclarationDetailsModal] = useState(false);

  const fetchData = async () => {
    setLoading(true);

    try {
      const [usersResponse, singleDeclarationResponse] = await Promise.all([
        userService.GetUsers(cookies.JWTtoken),
        declarationService.getSingleDeclaration(cookies.JWTtoken, id),
      ]);

      setUsers(usersResponse.data);
      setLoading(false);

      if (singleDeclarationResponse) {
        setSingleDeclarations(singleDeclarationResponse.data);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      if (error.response && error.response.data === "Forbidden") {
        window.location.href = "/";
      } else {
        console.error("Error fetching data:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const acceptRejectItem = (status, id) => {
    if (status === "approve") {
      declarationService.ApproveItem(cookies.JWTtoken, id).then(() => {
        fetchData();
      });
    } else {
      declarationService.DeclineItem(cookies.JWTtoken, id).then(() => {
        fetchData();
      });
    }
  };

  return (
    <>
      <LoadingScreen bool={loading} />
      {singleDeclarations && (
        <div>
          <Box pt={10} ml={2} mr={2} sx={{ marginLeft: "220px" }}>
            <DeclarationCard
              users={users}
              declaration={singleDeclarations}
              getSingleUserDeclaration={fetchData}
            />
            <Typography gutterBottom variant="h5" component="div" mt={2}>
              Declaration List
            </Typography>
            {singleDeclarations.items.map((singleDeclaration) => {
              return (
                <Card
                  sx={{
                    width: 345,
                    marginBottom: "5px",
                    background:
                      singleDeclaration.approved === 2 ? "#EBEBE4" : "inherit",
                  }}
                  key={singleDeclaration.id}
                  onClick={() => {
                    setDeclarationItem(singleDeclaration);
                    setDeclarationDetailsModal(true);
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography gutterBottom variant="h5" component="div">
                          {singleDeclaration.note}
                        </Typography>
                        <Box
                          mt="3px"
                          sx={{
                            color: declarationStatusColor(
                              singleDeclaration.approved
                            ),
                            fontWeight: "bold",
                          }}
                        >
                          {declarationStatus(singleDeclaration.approved)}
                        </Box>
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        Date:{" "}
                        {moment(singleDeclaration.declarationdate).format(
                          "DD-MM-YYYY HH:MM"
                        )}
                      </Typography>
                    </CardContent>
                    {singleDeclaration.approved === 0 && (
                      <Box
                        m={2}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {["approve", "reject"].map((val) => {
                          const color = val === "approve" ? 1 : 2;
                          return (
                            <Box mr={1} ml={1} key={val}>
                              <Button
                                sx={{
                                  textTransform: "capitalize",
                                  backgroundColor:
                                    declarationStatusColor(color),
                                  "&:hover": {
                                    backgroundColor:
                                      declarationStatusColor(color),
                                  },
                                }}
                                onClick={(e) => {
                                  acceptRejectItem(val, singleDeclaration.id);
                                  e.stopPropagation();
                                }}
                              >
                                {val}
                              </Button>
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </CardActionArea>
                </Card>
              );
            })}
          </Box>
        </div>
      )}
      {declarationDetailsModal && (
        <DeclarationDetailPopup
          open={declarationDetailsModal}
          handleClose={() => {
            setDeclarationDetailsModal(false);
          }}
          declarationItem={declarationItem}
        />
      )}
    </>
  );
};

export default SingleUserDeclaration;
