import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { useCookies } from "react-cookie";
import roleService from "../../Services/roleService";
import userService from "../../Services/UserService";
import DeletePopup from "../../Components/Popups/DeletePopup";
export default function TableRows({ row, getrequests }) {
  const [cookies] = useCookies(["JWTtoken"]);
  const [deletePopup, setDeletePopup] = useState(false);

  const handleClose = () => {
    setDeletePopup(!deletePopup);
  };

  const deleteRequest = () => {
    roleService
      .deleteRequest(row.requestID, cookies.JWTtoken)
      .then((data) => {
        getrequests();
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const acceptRequest = () => {
    userService
      .updateRole(row.userID, cookies.JWTtoken, { role: row.role })
      .then((data) => {
        roleService.deleteRequest(row.requestID, cookies.JWTtoken).then(() => {
          getrequests();
        });

        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <DeletePopup
        confirmDelete={deleteRequest}
        bool={deletePopup}
        handleClose={handleClose}
      />
      <TableRow
        key={row.requestID}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>{row.requestID}</TableCell>
        <TableCell>{row.firstname}</TableCell>
        <TableCell>{row.lastname}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.current_role}</TableCell>
        <TableCell>
          <b>{row.requested_role}</b>
        </TableCell>
        <TableCell>
          {moment(row.request_time).format("DD MMMM YYYY HH:mm")}
        </TableCell>
        <TableCell>{row.motivation}</TableCell>
        <TableCell>
          <IconButton
            onClick={() => {
              acceptRequest();
            }}
          >
            <DoneIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeletePopup(true);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
