import React, { useEffect, useState } from "react";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import { useCookies } from "react-cookie";
import { Box } from "@mui/material";
import educationService from "../../Services/EducationService";
import Fab from "@mui/material/Fab";
import UserEducationsTable from "./components/table/UserEducationsTable";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import AssignEducationModal from "../Users/AssignEducationModal";
import _ from "lodash";

export const sortingHeading = [
  "Deadline",
  "Afgerond",
];

const UserEducations = () => {
  const [cookies] = useCookies(["JWTtoken"]);
  const [loading, setLoading] = useState(false);
  const [educations, setEducations] = useState([]);
  const [educationsCopy, setEducationsCopy] = useState([]);
  const [assignEducation, setAssignEducation] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);

  const { id } = useParams();

  const getEducations = () => {
    setLoading(true);
    educationService
      .GetEducationsByCustomers(id, cookies.JWTtoken)
      .then((data) => {
        setEducations(data.data);
        setEducationsCopy(data.data)
      }).catch((error)=>{
        if (error.response && error.response.data === "Forbidden") {
          window.location.href = "/";
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
    setLoading(false);
  };
  useEffect(() => {
    id && getEducations();
  }, [id]);


  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    } else {
      SortingFunction();
    }
  }, [sorting]);

  const SortingFunction = () => {
    setLoading(true);

    setTimeout(() => {
      let sortedArray = [...educationsCopy];
      if (sorting.length > 0) {
        sorting.forEach(({ heading, order }) => {
          const sortingCriteriaMap = {
            "Deadline": "Deadline",
            "Afgerond": "Afgerond",
          
          };

          if (sortingCriteriaMap[heading]) {
            sortedArray = _.chain(sortedArray)
              .sortBy((item) => item[sortingCriteriaMap[heading]])
              .value();

            if (order === "dsc") {
              sortedArray = sortedArray.reverse();
            }
          }
        });
      }

      setLoading(false);
      setEducations(sortedArray);
    }, 3000);
  };

  return (
    <div>
      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: 16, right: "1%", color: "white" }}
        onClick={(e) => {
          setAssignEducation(true);
        }}
      >
        <AddIcon />
      </Fab>
      <LoadingScreen bool={loading} />
      <Box sx={{ width: "100%" }}>
        <UserEducationsTable
          educations={educations}
          getEducations={getEducations}
          sorting={sorting}
          setSortingFn={(heading, order) => {
            if (sortingHeading.includes(heading)) {
              if (order === "") {
                const updatedSorting = sorting.filter(
                  (srt) => srt.heading !== heading
                );
                setSorting(updatedSorting);
              } else {
                const updatedSorting = sorting.filter(
                  (srt) => srt.heading !== heading
                );

                setSorting([...updatedSorting, { heading, order }]);
              }
            }
          }}
        />
      </Box>
      {assignEducation && (
        <AssignEducationModal
          open={assignEducation}
          onClose={() => {
            setAssignEducation(false);
            getEducations()
          }}
          userId={id}
        />
      )}
    </div>
  );
};

export default UserEducations;
