import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import customerService from "../../Services/CustomerService";

export default function CreateCustomerPopup({
  bool,
  setbool,
  getCustomers,
  mode,
  editData,
}) {
  const [cookies] = useCookies(["JWTtoken"]);
  const [CustomerID, setCustomerID] = useState("");
  const [CustomerAlias, setCustomerAlias] = useState("");

  const handleClose = () => {
    setbool(false);
    setCustomerID("");
    setCustomerAlias("");
  };

  const createCustomer = () => {
    customerService
      .addCustomer(cookies.JWTtoken, { CustomerID, CustomerAlias })
      .then((data) => {
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        handleClose();
        getCustomers();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const editCustomer = () => {
    customerService
      .editCustomer(cookies.JWTtoken, { CustomerID, CustomerAlias })
      .then((data) => {
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        handleClose();
        getCustomers();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  useEffect(() => {
    if (editData) {
      setCustomerID(editData.customerId);
      setCustomerAlias(editData.alias);
    }
  }, [editData]);

  return (
    <div>
      <Dialog
        open={bool}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          sx={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle sx={{ fontWeight: "bold", pb: 0, mb: 0 }}>
            {mode === "add" ? "Add Customer" : "Edit Customer"}
          </DialogTitle>
        </Box>

        <DialogContent sx={{ maxWidth: "350px", pt: 0, mt: 0 }}>
          <Box>
            <TextField
              label="Customer ID"
              disabled={mode !== "add"}
              value={CustomerID}
              onChange={(e) => {
                setCustomerID(e.target.value);
              }}
            />

            <TextField
              label="Customer Alias"
              value={CustomerAlias}
              onChange={(e) => {
                setCustomerAlias(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Box m={2}>
              <Button
                onClick={(e) => {
                  mode === "add" ? createCustomer() : editCustomer();
                }}
              >
                {mode === "add" ? "Add" : "Edit"}
              </Button>
            </Box>
            <Box m={2}>
              <Button
                onClick={(e) => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
