import { api } from "./api";

class LocationService {
  getLocationsLogs = (token) =>
    api.get("/locations/log", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  getLocations = (token) =>
    api.get("location/", {
      headers: { Authorization: `Bearer ${token}` },
    });
  deleteLocation = (id, token) =>
    api.delete("location/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    });
  addLocation = (token, data) =>
    api.post("location/", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  editLocation = (token, data) =>
    api.put("location/update", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
}
let locationService = new LocationService();
export default locationService;
