/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import TableComponent from "./LocationLogsTableComponent";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useCookies } from "react-cookie";
import { Box } from "@mui/material";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import locationService from "../../Services/LocationService";
import AddLocationPopup from "../../Components/Popups/AddLocationPopup";

export default function LocationsLogs() {
  const [addLocation, setaddLocation] = React.useState(false);
  const [cookies] = useCookies(["JWTtoken"]);
  const [loading, setloading] = React.useState(false);
  const [locations, setlocations] = React.useState([]);

  const getlocations = () => {
    setloading(true);
    locationService
      .getLocationsLogs(cookies.JWTtoken)
      .then((data) => {
        setloading(false);
        setlocations(data.data);
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
        setlocations([]);
      });
  };
  React.useEffect(() => {
    getlocations();
  }, []);
  return (
    <>
      <LoadingScreen bool={loading} />
      <AddLocationPopup
        bool={addLocation}
        setbool={setaddLocation}
        getlocations={getlocations}
      />

      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: 16, right: "1%", color: "white" }}
        onClick={(e) => {
          setaddLocation(true);
        }}
      >
        <AddIcon />
      </Fab>
      <Box sx={{ width: "100%" }}>
        <TableComponent locations={locations} getlocations={getlocations} />
      </Box>
    </>
  );
}
