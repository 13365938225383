import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  TextField,
  Box,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import educationService from "../../../../Services/EducationService";
import LoadingScreen from "../../../../Components/LoadingScreen/LoadingScreen";
import DeletePopup from "../../../../Components/Popups/DeletePopup";
import { debounce, values } from "lodash";
import { Edit } from "@mui/icons-material";
import EditModal from "../editModal/EditModal";
import { useCookies } from "react-cookie";

const statuses = [
  { key: "Yes", value: 1 },
  { key: "No", value: 0 },
  { key: "No Applicable", value: 2 },
];

const UserEducationsRows = ({ userEducation, getEducations }) => {
  const [cookies] = useCookies(["JWTtoken"]);
  const [bool, setBool] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const defaultState = {
    fieldCheck: userEducation.fieldCheck,
    fieldDate:userEducation.fieldDate ?  moment(userEducation.fieldDate).format("YYYY-MM-DD"): null,
    isCompleted: userEducation.isCompleted,
    description: userEducation.description,
    educationID: userEducation.educationID,
    note: userEducation.note,
    userID: userEducation.userID,
    deadline:userEducation.deadline ?  moment(userEducation.deadline).format("YYYY-MM-DD"): null,
  };

  const [editFields, setEditFields] = useState(defaultState);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDelete = async () => {
    setBool(true);
    const result = await educationService.deleteUserEducations(
      userEducation.userID,
      userEducation.educationID
    );
    if (result.data) {
      toast.success(result.data, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    getEducations();
    setBool(false);
  };

  const handleUpdate = async(value) => {
    editFields.description = value;
    editFields.deadline = userEducation.deadline ? moment(userEducation.deadline).format("DD-MM-YYYY"): null
    editFields.fieldDate=userEducation.fieldDate ?  moment(userEducation.fieldDate).format("DD-MM-YYYY"): ""
    const result = await educationService.editUserEducation(editFields,cookies.JWTtoken);
    if (result.data) {
      toast.success(result.data, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }




  const handleChanges = async (e, type) => {
    setBool(true);
    let value = type === "fieldDate" ? e : e.target.value;
    if (type === "fieldDate") {
      value = moment(value).format("YYYY-MM-DD");
    }
    if (type === "fieldCheck") {
      value = e.target.checked;
    }

    setEditFields((prevState) => ({
      ...prevState,
      [type]: value,
    }));

    const editData = {
      ...editFields,
      [type]: value,
      deadline: moment(userEducation.deadline).format("DD-MM-YYYY"),
    };
    editData.fieldCheck = editData.fieldCheck ? 1 : 0;
    editData.fieldDate = moment(editData.fieldDate).format("DD-MM-YYYY");
    const result = await educationService.editUserEducation(editData, cookies.JWTtoken);
    if (result.data) {
      toast.success(result.data, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    getEducations();
    setBool(false);
  };

  const debouncedHandleChanges = debounce((value, type) => {
    handleChanges(value, type);
  }, 3000);
  return (
    <>
      <LoadingScreen bool={bool} />
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>{userEducation.educationID}</TableCell>
        <TableCell>{`${userEducation.educationType} | ${userEducation.educationDescription}`}</TableCell>
        <TableCell>
          <Box sx={{ display: "flex" }}>
            <Select
              sx={{ width: "100px" }}
              label="role"
              value={editFields.isCompleted}
              onChange={(e) => {
                handleChanges(e, "isCompleted");
              }}
            >
              {statuses.map((status) => {
                return (
                  <MenuItem value={status.value} key={status.value}>
                    {status.key}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </TableCell>
        <TableCell>{userEducation.note}</TableCell>
        <TableCell>
          {moment(userEducation.deadline).format("YYYY-MM-DD")}
        </TableCell>
        <TableCell>
          <TextField type="date" disabled />
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                minWidth: "130px",
                maxWidth: "130px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {userEducation.description}
            </Box>

            <IconButton
              onClick={() => {
                setEditModal(true);
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell>
          <TextField
            type="date"
            value={moment(editFields.fieldDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              const value = e.target.value;
              debouncedHandleChanges(value, "fieldDate");
            }}
          />
        </TableCell>
        <TableCell>
          <TextField placeholder="text" disabled />
        </TableCell>

        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <>
              <Checkbox
                checked={editFields.fieldCheck}
                onChange={(e) => {
                  handleChanges(e, "fieldCheck");
                }}
              />
            </>
          </Box>
        </TableCell>

        <TableCell>
          <IconButton
            onClick={() => {
              setDeleteModal(true);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
      {deleteModal && (
        <DeletePopup
          bool={deleteModal}
          confirmDelete={handleDelete}
          handleClose={() => {
            setDeleteModal(false);
          }}
        />
      )}
      {editModal && (<EditModal open={editModal} onClose={()=>{setEditModal(false)}} description={userEducation.description} onSuccess={handleUpdate} />)}
    </>
  );
};

export default UserEducationsRows;
