import React, { useEffect, useState } from "react";
import ModalWrapper from "./../../../../Components/ModalWrapper/ModalWrapper";
import { Select, TextField, MenuItem, Box, Button } from "@mui/material";
import progressConversationService from "../../../../Services/ProgressConversations";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

const EditModal = ({
  type,
  open,
  onClose,
  progressID,
  editData,
  onSuccess,
}) => {
  const [managers, setManagers] = useState([]);
  const [manager, setManager] = useState(editData.manager);
  const [review, setReview] = useState(editData.review);
  const [cookies] = useCookies();

  const getManagers = () => {
    progressConversationService.getManagers(cookies.JWTtoken).then((response) => {
      setManagers(response.data);
    });
  };

  useEffect(() => {
    getManagers();
  }, []);

  const editFunction = async () => {
    const editData = {
      progressID,
    };
    if (type === "manager") {
      editData.managerID = manager;
      const res = await progressConversationService.editPCManager(
        editData,
        cookies.JWTtoken
      );
      if (res.data) {
        toast.success("Review Updated", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        onSuccess();
        onClose();
      }
    } else {
      editData.progressText = review;
      const res = await progressConversationService.editPCReview(
        editData,
        cookies.JWTtoken
      );
      if (res.data) {
        toast.success(res.data, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
      onSuccess();
      onClose();
    }
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      title={`Edit ${type === "manager" ? "Manager" : "Progress Review"}`}
    >
      <Box mt={1} sx={{ width: "100%" }}>
        {type === "manager" ? (
          <Select
            fullWidth
            margin="normal"
            variant="standard"
            value={manager}
            label="Manager"
            onChange={(e) => {
              setManager(e.target.value);
            }}
          >
            {managers.map((manager) => (
              <MenuItem value={manager.userID}>
                {manager.firstName}&nbsp;{manager.lastName}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <TextField
            margin="normal"
            variant="outlined"
            label="Review"
            multiline
            minRows={5}
            value={review}
            onChange={(e) => {
              setReview(e.target.value);
            }}
          />
        )}
      </Box>
      <Box mt={1} sx={{ display: "flex", justifyContent: "right" }}>
        <Box m={2}>
          <Button onClick={editFunction}>Edit</Button>
        </Box>
        <Box m={2}>
          <Button
            onClick={(e) => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default EditModal;
