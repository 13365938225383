/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import DashboardCard from "../../Components/DashboardCard/DasshboardCard";
import gateWayService from "../../Services/GateWayService";
import { useCookies } from "react-cookie";
import userService from "../../Services/UserService";
import tagService from "../../Services/TagsService";
export const MarginBox = styled(Box)({
  width: "100%",
});
export default function TopWidgets(props) {
  const [cookies] = useCookies(["JWTtoken"]);
  const [gateway, setGateway] = useState(0);
  const [tags, setTags] = useState(0);
  const [users, setUsers] = useState(0);
  const [recentTags, setRecentTags] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const gatewaysPromise = gateWayService
      .getGateWays(cookies.JWTtoken)
      .then((data) => {
        setGateway(data.data.data.length);
      });

    const usersPromise = userService.GetUsers(cookies.JWTtoken).then((data) => {
      setUsers(data.data.length);
    });

    const tagsPromise = tagService.getTags(cookies.JWTtoken).then((data) => {
      setTags(data.data.tags.length);
      setRecentTags(0);

      const now = new Date();
      const recentTagsCount = data.data.tags.reduce((count, tag) => {
        const then = new Date(tag.Date_Last_active);
        const msBetweenDates = Math.abs(then.getTime() - now.getTime());
        const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

        if (Math.floor(hoursBetweenDates) < 24) {
          return count + 1;
        }
        return count;
      }, 0);

      setRecentTags(recentTagsCount);
    });

    Promise.all([gatewaysPromise, usersPromise, tagsPromise]).finally(() =>
      setLoading(false)
    );
  }, []);

  return (
    <Box pt={7} ml={2} mr={2} sx={{ marginLeft: "220px" }}>
      <LoadingScreen bool={loading} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box m={2} sx={{ width: "100%" }}>
          <DashboardCard name={"GateWays"} num={gateway} />
        </Box>
        <Box m={2} sx={{ width: "100%" }}>
          <DashboardCard name={"Users"} num={users} />
        </Box>

        <Box m={2} sx={{ width: "100%" }}>
          <DashboardCard name={"Tags"} num={tags} />
        </Box>
        <Box m={2} sx={{ width: "100%" }}>
          <DashboardCard name={"Recent Tags"} num={recentTags} />
        </Box>
      </Box>
    </Box>
  );
}
