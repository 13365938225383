import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, Box, Button } from "@mui/material";
import moment from "moment";
import { useCookies } from "react-cookie";
import declarationService from "../../../../../../Services/DeclarationService";
import {
  currency,
  declarationStatus,
  declarationStatusColor,
  getNumberOfItems,
  getSumOfGrandTotal,
} from "../../../../declarationUtils";

const DeclarationCard = ({ declaration, getSingleUserDeclaration, users }) => {
  const [cookies] = useCookies(["JWTtoken"]);

  const user = users.filter((user) => user.userID === declaration.userid)[0];

  const items = declaration ? getNumberOfItems(declaration) : 0;
  const total = declaration ? getSumOfGrandTotal(declaration) : 0;

  const declineDeclaration = () => {
    declarationService
      .DeclineDeclaration(cookies.JWTtoken, declaration.id)
      .then(() => {
        getSingleUserDeclaration();
      });
  };
  const acceptDeclaration = () => {
    declarationService
      .ApproveDeclaration(cookies.JWTtoken, declaration.id)
      .then(() => {
        getSingleUserDeclaration();
      });
  };
  return (
    <Card sx={{ width: 345 }}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {declaration.note}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ display: "flex" }}
          >
            Date:{" "}
            {moment(declaration.declarationdate).format("DD/MM/YYYY HH:MM")} |
            status:&nbsp;
            <Box
              sx={{
                color: declarationStatusColor(declaration.approved),
                fontWeight: "bold",
              }}
            >
              {declarationStatus(declaration.approved)}
            </Box>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <div className="d-flex">
              <span className="col-sm-5">Bank Number:</span>
              <span className="col-sm-8">{declaration.banknumber} </span>
            </div>
            <div className="d-flex">
              <span className="col-sm-5">Submitted by:</span>
              <span className="col-sm-8">
                {user?.firstname}&nbsp;{user?.lastname}{" "}
              </span>
            </div>
            <div className="d-flex">
              <span className="col-sm-5">Declaration items:</span>
              <span className="col-sm-8">{items}</span>
            </div>
            <div className="d-flex">
              <span className="col-sm-5">Total Price:</span>
              <span className="col-sm-8">
                {total.toFixed(2)} {currency("Euro")}
              </span>
            </div>
          </Typography>
          {!declaration.approved && (
            <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Box mr={1} ml={1}>
                <Button
                  sx={{
                    backgroundColor: declarationStatusColor(1),
                    "&:hover": { backgroundColor: declarationStatusColor(1) },
                  }}
                  onClick={(e) => {
                    acceptDeclaration();
                    e.stopPropagation();
                  }}
                >
                  Approve
                </Button>
              </Box>
              <Box mr={1} ml={1}>
                <Button
                  sx={{
                    backgroundColor: declarationStatusColor(2),
                    "&:hover": { backgroundColor: declarationStatusColor(2) },
                  }}
                  onClick={(e) => {
                    declineDeclaration();
                    e.stopPropagation();
                  }}
                >
                  Decline
                </Button>
              </Box>
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default DeclarationCard;
