import React, { useState } from "react";
import {
  TextField,
  Button,
  Card,
  Box,
  Typography,
  CardContent,
} from "@mui/material";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import userService from "../../Services/UserService";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useNavigate, Navigate } from "react-router-dom";
import TwoFactorAuth from "./components/TwoFactorAuth";
import OtpModal from "./components/OtpModal";
import { useAppContext } from "../../context/AppContext";

const Title = styled(Typography)({ fontSize: "24px", fontWeight: "bold" });

const Login = () => {
  const {
    updateAppState,
    appState: { isLoggedIn },
  } = useAppContext();
  const navigate = useNavigate();
  const [email, setemail] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [loading, setloading] = useState(false);
  const [token, setToken] = useState("");
  const [userDetail, setUserDetail] = useState({});
  const [twoFA, setTwoFA] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["JWTtoken"]);

  const cookieSetter = () => {
    setCookie("JWTtoken", JSON.stringify(token), {
      maxAge: 3600,
    });
    updateAppState({ isLoggedIn: true, role: userDetail.role });
    navigate("/");
  };
  const userChecking = (token) => {
    const user = jwtDecode(token);
    setUserDetail(user);

    if (user.role < 3) {
      setloading(false);
      removeCookie("JWTtoken");
      toast.error("NO permission to login", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      userService.get2FAStatus(email).then((data) => {
        if (data.data.message === "2FA is not set") {
          setTwoFA(true);
        } else if (data.data.message === "2FA is set") {
          setOtpModal(true);
        } else {
          toast.error(data.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      });
    }
  };

  const Login = () => {
    setloading(true);
    userService
      .Login({ email, password })
      .then((data) => {
        setloading(false);
        setToken(data.data.token);
        userChecking(data.data.token);
      })
      .catch((err) => {
        setloading(false);
        toast.error(err.message, {
        });
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/pages/home" />;
  }

  return (
    <>
      <LoadingScreen bool={loading} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "220px",
        }}
      >
        <Card
          sx={{
            maxWidth: 400,
            padding: "20px",
            backgroundColor: "#fafafa",
            width: "300px",
          }}
        >
          <CardContent>
            <Title align="center">SIGN IN</Title>
            <Box sx={{}}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  required
                  id="filled-required"
                  label="email"
                  defaultValue={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                />
              </Box>

              <>
                <TextField
                  label="password"
                  type="password"
                  defaultValue={password}
                  onChange={(e) => {
                    setpassword(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      Login();
                    }
                  }}
                />
              </>
              <Box mt={2}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() => {
                    Login();
                  }}
                >
                  LOGIN
                </Button>
              </Box>
              <Box mt={2}>
                <Typography component={Link} to={"/forgot-password"}>
                  Forgot Password?
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {twoFA && (
        <TwoFactorAuth
          bool={twoFA}
          token={token}
          user={userDetail}
          handleClose={() => {
            setTwoFA(false);
          }}
          onSuccess={cookieSetter}
        />
      )}
      {otpModal && (
        <OtpModal
          bool={otpModal}
          token={token}
          email={userDetail.email}
          handleClose={() => {
            setOtpModal(false);
          }}
          onSuccess={cookieSetter}
        />
      )}
    </>
  );
};

export default Login;
