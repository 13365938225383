/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import TableComponent from "./CustomersTableComponent";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useCookies } from "react-cookie";
import { Box } from "@mui/material";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import customerService from "../../Services/CustomerService";
import CreateCustomerPopup from "../../Components/Popups/CreateCustomerPopup";

export default function Customers() {
  const [registerpopup, setregisterpopup] = React.useState(false);
  const [cookies] = useCookies(["JWTtoken"]);
  const [loading, setloading] = React.useState(false);
  const [customers, setcustomers] = React.useState([]);

  const getCustomers = () => {
    setloading(true);
    customerService
      .GetCustomers(cookies.JWTtoken)
      .then((data) => {
        setloading(false);
        setcustomers(data.data);
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
        setcustomers([]);
        if (error.response.data === "Forbidden") {
          window.location.href = "/";
        }
      });
  };
  React.useEffect(getCustomers, []);
  return (
    <>
      <LoadingScreen bool={loading} />
      <CreateCustomerPopup
        mode="add"
        bool={registerpopup}
        setbool={setregisterpopup}
        getCustomers={getCustomers}
      />

      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: 16, right: "1%", color: "white" }}
        onClick={(e) => {
          setregisterpopup(true);
        }}
      >
        <AddIcon />
      </Fab>
      <Box sx={{ width: "100%" }}>
        <TableComponent customers={customers} getCustomers={getCustomers} />
      </Box>
    </>
  );
}
