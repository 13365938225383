import { api } from "./api";

class TagService {
  getTags = (token) =>
    api.get(
      "tags/",

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  deleteTag = (id, token) =>
    api.delete("tags/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    });

  updateTag = (id, token, data) =>
    api.put("tags/" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  addTag = (token, data) =>
    api.post("tags/", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
}
let tagService = new TagService();
export default tagService;
