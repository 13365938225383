import { api } from "./api";

class EducationService {
  GetEducations = (token) =>
    api.get("educations/", {
      headers: { Authorization: `Bearer ${token}` },
    });

  GetEducationsByCustomers = (id, token) =>
    api.get(`educations/getEducationFromUser/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

  deleteEducations = (id, token) =>
    api.delete(`educations/delete/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

  addEducation = (data, token) =>
    api.post("educations/add", data, {
      headers: { Authorization: `Bearer ${token}` },
    });

  editEducation = (data, token) =>
    api.put("educations/update", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  editUserEducation = (data, token) =>
    api.put("educations/assign/update", data, {
      headers: { Authorization: `Bearer ${token}` },
    });

  assignUserEducation = (data, token) =>
    api.post("educations/assign", data, {
      headers: { Authorization: `Bearer ${token}` },
    });

  deleteUserEducations = (userID, educationID, token) =>
    api.delete(`educations/assign/delete/${userID}/${educationID}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
}
let educationService = new EducationService();
export default educationService;
