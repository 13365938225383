import { api } from "./api";
import { jwtDecode } from "jwt-decode";

class UserService {
  Login = (data, token) => api.post("users/login/", data);

  reqToken = (data) => api.post("users/password/reset", data);

  resetPassword = (token, data) =>
    api.post("users/password/reset/" + token, data);

  Register = (data) => api.post("users/register/", data);

  updateRole = (id, token, data) =>
    api.put("users/role/" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

  updatePassword = (id, token, data) =>
    api.put("users/password/" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

  reset2FA = (token, data) =>
    api.put("users/2fa-reset/", data, {
      headers: { Authorization: `Bearer ${token}` },
    });

  generate2FA = (data) => api.post("users/2fa-gen/", data);

  verify2FA = (data) => api.post("users/2fa-verify/", data);

  deleteUser = (id, token) =>
    api.delete("users/user/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    });

  GetUsers = (token) =>
    api.get("users/", { headers: { Authorization: `Bearer ${token}` } });

  get2FAStatus = (data) => api.get("users/2fa-status/" + data);

  GetRole = (token) => jwtDecode(token);
}
let userService = new UserService();
export default userService;
