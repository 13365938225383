import PeopleIcon from "@mui/icons-material/People";
import BadgeIcon from "@mui/icons-material/Badge";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ThreePIcon from "@mui/icons-material/ThreeP";
import LinkIcon from "@mui/icons-material/Link";
import StyleIcon from "@mui/icons-material/Style";
import ReceiptIcon from "@mui/icons-material/Receipt";

export const appControl = [
  { id: 0, title: "Users", path: "/users", icon: <PeopleIcon /> },
  { id: 1, title: "Tags", path: "/tags", icon: <StyleIcon /> },
  { id: 2, title: "Gateways", path: "/gateway", icon: <LinkIcon /> },
  {
    id: 3,
    title: "Role Requests",
    path: "/role-requests",
    icon: <BadgeIcon />,
  },
  { id: 4, title: "Locations", path: "/locations", icon: <LocationOnIcon /> },
  { id: 5, title: "Customers", path: "/customers", icon: <ThreePIcon /> },
  {
    id: 6,
    title: "Declarations",
    path: "/declarations",
    icon: <ReceiptIcon />,
  },
  {
    id: 7,
    title: "Educations",
    path: "/educations",
    icon: <ReceiptIcon />,
  },
  {
    id: 8,
    title: "Progress Conversations",
    path: "/progress-conversations",
    icon: <ReceiptIcon />,
  },
];
