/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, IconButton, Divider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCookies } from "react-cookie";
import customerService from "../../Services/CustomerService";
import DeletePopup from "../../Components/Popups/DeletePopup";
export default function SelectComponent({
  data,
  locationID,
  getlocations,
  setCheck,
}) {
  const [cookies] = useCookies(["JWTtoken"]);
  const [customers, setcustomers] = useState();
  const [Alias, setAlias] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);

  const handleClose = () => {
    setDeletePopup(!deletePopup);
  };

  useEffect(() => {
    setCheck?.(true);
    customerService.GetCustomers(cookies.JWTtoken).then((data) => {
      setcustomers(data.data);
      setCheck?.(false);
    });
  }, []);

  useEffect(() => {
    setAlias(
      customers?.filter((mac) => {
        return mac.CustomerID === data;
      })
    );
  }, [customers]);

  const unBindCustomer = () => {
    customerService
      .unBindLocation(cookies.JWTtoken, {
        LocationID: locationID,
        CustomerID: data,
      })
      .then((data) => {
        getlocations();
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <DeletePopup
        confirmDelete={unBindCustomer}
        bool={deletePopup}
        handleClose={handleClose}
      />
      {Alias ? (
        <>
          {data && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box m={0.5}>{data + " | " + Alias[0]?.Alias}</Box>
              <IconButton
                onClick={(e) => {
                  setDeletePopup(true);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
          <Divider />
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size="1rem" color="success" />
        </Box>
      )}
      {!data && (
        <>
          {" "}
          No Customers <Divider />
        </>
      )}

      <br />
    </>
  );
}
