export const currency = (currency) => {
  if (currency === "Euro") return "€";
};

export const declarationStatus = (status) => {
  if (status === 0) return "Progress";
  if (status === 1) return "Approved";
  if (status === 2) return "Rejected";
};
export const declarationStatusColor = (status) => {
  if (status === 0) return "#005E9D";
  if (status === 1) return "#198754";
  if (status === 2) return "#ff0e0e";
};

export const statuses = [
  { value: "ALL", title: "ALL" },
  { value: "0", title: "In Progress" },
  { value: "1", title: "Approved" },
  { value: "2", title: "Declined" },
];

export const getNumberOfItems = (data) => {
  let totalItems = 0;

  totalItems = data.items.length;
  return totalItems;
};

export const getSumOfGrandTotal = (data) => {
  let sumGrandTotal = 0;

  data.items.forEach((item) => {
    if (item.receipts && item.receipts.length > 0) {
      item.receipts.forEach((receipt) => {
        sumGrandTotal += receipt.grandtotal || 0;
      });
    }
  });

  return sumGrandTotal;
};

export const getSumOfNonCostItems = (data) => {
  let sumNonCostItems = 0;

  data.items.forEach((item) => {
    if (item.iscosts === 0) {
      sumNonCostItems += item.value || 0;
    }
  });

  return sumNonCostItems;
};
