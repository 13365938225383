export const customersHeading = ["Customer ID", "Alias", "Action"];

export const gatewaysHeading = [
  "GateWay ID",
  "MAC",
  "ALIAS",
  "Last Active",
  "Date Added",
  "Actions",
];
export const locationsHeading = [
  "ID",
  "Location ID",
  "Description",
  "Customers",
  "Action",
];
export const locationLogsHeading = ["Location ID", "Description", "Action"];
export const searchTableHeading = ["ID", "Name", "Address"];

export const roleRequestsHeading = [
  "Request ID",
  "First Name",
  "Last Name",
  "Email",
  "Current Role",
  "Requested Role",
  "Time",
  "Motivation",
  "Delete",
];
export const tagsHeading = [
  "Tag ID",
  "Last RSSI",
  "MAC",
  "Fixed",
  "Object Number",
  "Battery",
  "Location",
  "Note",
  "Description",
  "Last Active",
  "Date Added",
  "Edit",
  "Delete",
];
export const searchDetailTableHeading = [
  "LM Nummer",
  "Objectnummer Klant",
  "Omshrijving",
  "Afdeling",
  "Ruimte",
  "Status",
  "Serienummer",
  "Laatst OH",
  "Volgend PO",
  "PO freq.",
  "Action",
];

export const usersHeading = [
  "User ID",
  "First Name",
  "Last Name",
  "Email",
  "Customers",
  "Role",
  "Edit Password",
  "2FA",
  "Educations",
  "Delete",
];
export const educationsHeading = [
  "Education ID",
  "Type",
  "Description",
  "Customer",
  "Actions",
];
export const progressConversationHeading = [
  "User ID",
  "User Name",
  "Voortgangsgesprek Q1",
  "Voortgangsgesprek Q2",
  "Voortgangsgesprek Q3",
  "Beoordelingsgesprek",
  "Manager",
];
export const userEducations = [
  "Education ID",
  "Soort",
  "Status",
  "Beschrijving",
  "Deadline",
  "Afgerond",
  "Toelitching",
  "Veldultleg",
  "door medewerker",
  "Completed",
  "Actions",
];
