import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import {
  Box,
  IconButton,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import userService from "../../Services/UserService";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useCookies } from "react-cookie";
import SelectComponent from "./SelectComponent";
import BindCustomerPopup from "../../Components/Popups/BindCustomerPopup";
import { roles } from "../../Common/roles";
import { useState } from "react";
import DeletePopup from "../../Components/Popups/DeletePopup";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useEffect } from "react";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import AssignEducationModal from "./AssignEducationModal";

export default function TableRows({ row, getUsers }) {
  const [roleCheck, setRoleCheck] = useState(true);
  const [passwordCheck, setPasswordCheck] = useState(true);
  const [password, setPassword] = useState("");
  const [bindBool, setBindBool] = useState(false);
  const [cookies] = useCookies(["JWTtoken"]);
  const [check, setCheck] = useState(true);
  const [deletePopup, setDeletePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assignEducation, setAssignEducation] = useState(false);

  const handleClose = () => {
    setDeletePopup(!deletePopup);
  };

  const handleChange = (event) => {
    userService
      .updateRole(row.userID, cookies.JWTtoken, { role: event.target.value })
      .then((data) => {
        getUsers();
        setRoleCheck(true);
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (row.customers.length < 1) {
      setCheck(false);
    }
  }, [row.customers]);

  const cancel = () => {
    setPasswordCheck(true);
    setPassword("");
  };
  const ChangePassword = () => {
    userService
      .updatePassword(row.userID, cookies.JWTtoken, { password })
      .then((data) => {
        getUsers();
        cancel();
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteUser = () => {
    userService
      .deleteUser(row.userID, cookies.JWTtoken)
      .then((data) => {
        getUsers();
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const reset2FA = () => {
    setLoading(true);
    userService
      .reset2FA(cookies.JWTtoken, { email: row.email })
      .then((data) => {
        getUsers();
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  return (
    <>
      <LoadingScreen bool={loading} />
      {deletePopup && (
        <DeletePopup
          confirmDelete={deleteUser}
          bool={deletePopup}
          handleClose={handleClose}
        />
      )}

      {bindBool && (
        <BindCustomerPopup
          bool={bindBool}
          setbool={setBindBool}
          userID={row.userID}
          getUsers={getUsers}
          BindedCustomers={row.customers}
        />
      )}

      {assignEducation && (
        <AssignEducationModal
          open={assignEducation}
          onClose={() => {
            setAssignEducation(false);
          }}
          userId={row.userID}
        />
      )}
      <TableRow
        key={row.userID}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>{row.userID}</TableCell>
        <TableCell>{row.firstname}</TableCell>
        <TableCell>{row.lastname}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>
          <FormControl sx={{ m: -1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small">Customers</InputLabel>
            <Select onChange={handleChange}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  align: "center",
                  justifyContent: "center",
                }}
              >
                <Box>
                  {row.customers.map((data) => {
                    return (
                      <Box mr={1} ml={1} key={data}>
                        <SelectComponent
                          userID={row.userID}
                          data={data}
                          setcheck={setCheck}
                          getUsers={getUsers}
                        />
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    disabled={check}
                    onClick={(e) => {
                      setBindBool(true);
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </Select>
          </FormControl>
        </TableCell>

        <TableCell
          sx={{ display: "flex", align: "center", flexWrap: "nowrap" }}
        >
          <Box sx={{ display: "flex" }}>
            <Select
              sx={{ width: "100px" }}
              disabled={roleCheck}
              label="role"
              value={row.role}
              onChange={handleChange}
            >
              {roles.map((role) => {
                return (
                  <MenuItem value={role.value} key={role.id}>
                    {role.title}
                  </MenuItem>
                );
              })}
            </Select>

            <IconButton
              onClick={() => {
                setRoleCheck(false);
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", align: "center" }}>
            <TextField
              sx={{ width: "12ch" }}
              margin="none"
              disabled={passwordCheck}
              placeholder=" New Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  ChangePassword();
                }
              }}
            />
            {passwordCheck ? (
              <IconButton
                onClick={() => {
                  setPasswordCheck(false);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            ) : (
              <>
                <IconButton
                  onClick={() => {
                    ChangePassword();
                  }}
                >
                  <CheckIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => {
                    cancel();
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            )}
          </Box>
        </TableCell>
        <TableCell>
          {row.role === 4 && row.mfa ? (
            <IconButton onClick={reset2FA}>
              <RestartAltIcon fontSize="small" />
            </IconButton>
          ) : (
            <Box ml={2}>{"-"}</Box>
          )}
        </TableCell>
        <TableCell>
          <Box
            onClick={() => {
              setAssignEducation(true);
            }}
            sx={{
              color: "blue",
              textDecoration: "underline",
              width: "120px",
              cursor: "pointer",
            }}
          >
            Assign Education
          </Box>
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => {
              setDeletePopup(true);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
