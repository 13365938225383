import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { useState } from "react";
import { Select, InputLabel, MenuItem, Typography } from "@mui/material";

export default function NotificationReceivers({ bool, setBool, users }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const handleClose = () => {
    setBool(false);
  };

  return (
    <div>
      <Dialog
        open={bool}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{}}
      >
        <Box
          sx={{
            maxWidth: "25%",
            width: "500px",
            minHeight: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle sx={{ fontWeight: "bold" }}>
            Notification Receivers
          </DialogTitle>
        </Box>

        <DialogContent>
          <Box>
            <InputLabel>Select Emails</InputLabel>
            <Select
              sx={{
                mr: 1,
                whiteSpace: "pre-wrap",
              }}
              multiple
              fullWidth
              multiline
              value={selectedUsers}
              onChange={(e) => {
                const {
                  target: { value },
                } = e;
                setSelectedUsers(value);
              }}
              // renderValue={(selected) => (
              //   <div>
              //     {selected.map((value) => (
              //       <Typography key={value} variant="body2">
              //         {users.find((user) => user.userID === value)?.email}
              //       </Typography>
              //     ))}
              //   </div>
              // )}
            >
              {users.map((user) => {
                return (
                  <MenuItem key={user.userID} value={user.userID}>
                    {user.email}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Box m={2}>
              <Button onClick={(e) => {}}>Confirm</Button>
            </Box>
            <Box m={2}>
              <Button
                onClick={(e) => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
