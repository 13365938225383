import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";

const ModalWrapper = ({ open, onClose,  title, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Box
        sx={{
          minWidth: "600px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>{title}</DialogTitle>
      </Box>

      <DialogContent sx={{ paddingTop: 0, width: "100%" }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default ModalWrapper;
