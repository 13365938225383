/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/LogicMedicalLogo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { Home } from "@mui/icons-material";
import ComputerIcon from "@mui/icons-material/Computer";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { appControl } from "./data";
import { useCookies } from "react-cookie";
import { useAppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";

const StyledListItem = styled(ListItem)({ height: "35px" });
const StyledListItemButton = styled(ListItemButton)({
  height: "35px",
  paddingLeft: "10px",
});
const StyledTypography = styled(Typography)({
  fontWeight: "bold",
  marginLeft: "10px",
});

export default function Sidebar() {
  const navigate = useNavigate();
  const {
    appState: { role },
    updateAppState,
  } = useAppContext();
  const [_cookies, removeCookie] = useCookies(["JWTtoken"]);

  return (
    <Box sx={{ width: "300px" }}>
      <Box
        position="fixed"
        sx={{
          width: "200px",
          height: "100%",
          backgroundColor: "#fafafa",
          zIndex: 1,
          paddingTop: "70px",
        }}
      >
        <List disablePadding>
          {role > 3 && (
            <>
              <StyledTypography>Dashboard</StyledTypography>
              <StyledListItem disablePadding>
                <StyledListItemButton component={Link} to="/">
                  <ListItemIcon>
                    <Home />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </StyledListItemButton>
              </StyledListItem>
              <StyledTypography>App Control</StyledTypography>
              {appControl.map((option) => {
                return (
                  <StyledListItem disablePadding key={option.id}>
                    <StyledListItemButton component={Link} to={option.path}>
                      <ListItemIcon>{option.icon}</ListItemIcon>
                      <ListItemText
                        sx={{
                          "& span": {
                            lineHeight: "1",
                          },
                        }}
                        primary={option.title}
                      />
                    </StyledListItemButton>
                  </StyledListItem>
                );
              })}
            </>
          )}
          {role > 2 && (
            <>
              <StyledTypography>Ruimtecontrole</StyledTypography>
              <StyledListItem disablePadding>
                <StyledListItemButton component={Link} to="search">
                  <ListItemIcon>
                    <ComputerIcon />
                  </ListItemIcon>
                  <ListItemText primary="Ruimtecontrole" />
                </StyledListItemButton>
              </StyledListItem>
            </>
          )}

          <StyledTypography>Action</StyledTypography>
          <StyledListItem disablePadding>
            <StyledListItemButton
              onClick={() => {
                removeCookie("JWTtoken");
                updateAppState({ isLoggedIn: false });
                navigate("/login");
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </StyledListItemButton>
          </StyledListItem>
        </List>

        <Box sx={{ position: "absolute", left: "0", bottom: "100px" }}>
          <img src={Logo} width="200px" alt="Logo" />
          <Box
            mr={2}
            sx={{
              fontSize: "10px",
              display: "flex",
              justifyContent: "right",
              lineHeight: 0,
            }}
          >
            {process.env.REACT_APP_VERSION}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
