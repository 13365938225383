/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import TableComponent from "./GateWayTableComponent";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import CreateGateWayPopup from "../../Components/Popups/CreateGateWayPopup";
import { useCookies } from "react-cookie";
import { Box } from "@mui/material";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import gateWayService from "../../Services/GateWayService";

export default function Gateway() {
  const [createGateWayPopup, setcreateGateWayPopup] = React.useState(false);
  const [cookies] = useCookies(["JWTtoken"]);
  const [loading, setloading] = React.useState(false);
  const [gateway, setgateway] = React.useState([]);

  const getgateway = () => {
    setloading(true);
    gateWayService
      .getGateWays(cookies.JWTtoken)
      .then((data) => {
        setloading(false);
        setgateway(data.data.data);
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
        setgateway([]);
      });
  };
  React.useEffect(getgateway, []);
  return (
    <>
      <LoadingScreen bool={loading} />
      <CreateGateWayPopup
        bool={createGateWayPopup}
        setbool={setcreateGateWayPopup}
        getgateway={getgateway}
      />

      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: 16, right: "1%", color: "white" }}
        onClick={(e) => {
          setcreateGateWayPopup(true);
        }}
      >
        <AddIcon />
      </Fab>
      <Box sx={{ width: "100%" }}>
        <TableComponent gateway={gateway} getgateway={getgateway} />
      </Box>
    </>
  );
}
