import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";
import moment from "moment";

const StyledRow = ({ heading, text, bold }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Typography sx={{ fontWeight: bold ? "bold" : "", width: "40%" }}>
        {heading}:&nbsp;
      </Typography>
      <Typography
        sx={{
          fontWeight: bold ? "bold" : "",
          width: "60%",
          wordBreak: "break-all",
        }}
      >
        {text ? text : "N/A"}
      </Typography>
    </Box>
  );
};

export default function StatusDetailPopup({ bool, setbool, row }) {
  const handleClose = () => {
    setbool(false);
  };

  return (
    <div>
      <Dialog
        open={bool}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          sx={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle sx={{ fontWeight: "bold", pb: 0, mb: 0 }}>
            Status Information
          </DialogTitle>
        </Box>

        <DialogContent
          sx={{
            width: "450px",
            pt: 0,
            mt: 0,
          }}
        >
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <StyledRow heading="LM Nummer" text={row?.SERVICEOBJECTID} />
            <StyledRow
              heading="Objectnummer Klant"
              text={row?.CUSTOMEROBJECT}
            />
            <StyledRow heading="Omshrijvingr" text={row?.OBJECTTYPEDESC} />
          </Box>
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <StyledRow
              bold
              heading="Last Update"
              text={
                row["CF_IB-0140"]
                  ? moment(row["CF_IB-0140"]).format("YYYY-MM-DD")
                  : row["CF_IB-0140"]
              }
            />
            <StyledRow
              bold
              heading="Status"
              text={row["CF_IB-0150"]?.split(" ")[0]}
            />
            {row["CF_IB-0150"]?.split(" ")[2] ? (
              <>
                <StyledRow
                  bold
                  heading="Commentaar"
                  text={row["CF_IB-0150"]?.split(" ")[2]}
                />
              </>
            ) : (
              <></>
            )}
            <StyledRow
              bold
              heading="Changed By"
              text={row["CF_IB-0150"]?.split(" ")[1]}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Box m={2}>
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
