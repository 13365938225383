import { api } from "./api";

class DeclarationService {
  getDeclarations = (token) =>
    api.get("declaration/", {
      headers: { Authorization: `Bearer ${token}` },
    });
  DeclineDeclaration = (token, id) =>
    api.put(
      `declaration/reject/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

  ApproveDeclaration = (token, id) =>
    api.put(
      `declaration/approve/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  ApproveItem = (token, id) =>
    api.put(
      `declaration/approve/item/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  DeclineItem = (token, id) =>
    api.put(
      `declaration/reject/item/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

  getSingleUserDeclarations = (token, id) =>
    api.get(`declaration/users/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

  getSingleDeclaration = (token, id) =>
    api.get(`declaration/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

  getReceiptBlob = (token, id) =>
    api.get(`/declaration/receipt/blob/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
}
let declarationService = new DeclarationService();
export default declarationService;
