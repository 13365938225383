import React from "react";
import {
  TextField,
  Card,
  Box,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import userService from "../../Services/UserService";
const Title = styled(Typography)({ fontSize: "24px", fontWeight: "bold" });

const Newpassword = (props) => {
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setloading] = React.useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  const updatePassword = () => {
    setloading(true);
    userService
      .resetPassword(token, { password })
      .then((data) => {
        setloading(false);
        window.location.href = "/login";
        toast.success(data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };
  return (
    <>
      <LoadingScreen bool={loading} />
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "220px",
          }}
        >
          <Card sx={{ minWidth: 300, maxWidth: 300, padding: "20px" }}>
            <CardContent>
              <Title align="center">NEW PASSWORD</Title>
              <Box>
                <>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="New Password"
                    type="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        updatePassword();
                        // write your functionality here
                      }
                    }}
                  />
                </>
                <>
                  <TextField
                    label="Confirm Password"
                    type="Password"
                    fullWidth
                    variant="standard"
                    value={confirmPassword}
                    helperText={
                      confirmPassword === password && password !== ""
                        ? ""
                        : "Both Passwords Should Match"
                    }
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        updatePassword();
                        // write your functionality here
                      }
                    }}
                  />
                </>

                <Box mt={2}>
                  <Button
                    disabled={
                      confirmPassword === password && password !== ""
                        ? false
                        : true
                    }
                    variant="contained"
                    sx={{ margin: "0px", width: "100%" }}
                    onClick={(e) => {
                      updatePassword();
                    }}
                  >
                    Update Password
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default Newpassword;
