import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, Box } from "@mui/material";
import DeclarationTableRows from "./DeclarationTableRows";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import _ from "lodash";

export default function DeclarationTable({
  declarations,
  getDeclarations,
  users,
}) {
  const [sortedArray, setSortedArray] = useState(declarations);
  const [sortingName, setSortingName] = useState("");
  const [sortingDate, setSortingDate] = useState("");

  const sort = (heading, order) => {
    heading === "User Name" ? setSortingDate("") : setSortingName("");
    if (order === "") {
      setSortedArray([...declarations]);
      heading === "User Name" ? setSortingName("") : setSortingDate("");
      return;
    }

    const sortingCriteria = {
      "User Name": "userid",
      "Declaration Date": "declarationdate",
    };

    if (sortingCriteria[heading]) {
      const sorted = _.sortBy([...declarations], sortingCriteria[heading]);

      setSortedArray(order === "asc" ? sorted : sorted.reverse());
      heading === "User Name" ? setSortingName(order) : setSortingDate(order);
    }
  };

  useEffect(() => {
    setSortedArray(declarations);
  }, [declarations]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User ID</TableCell>
            <TableCell>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <span>User Name</span>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4px",
                  }}
                >
                  <>
                    {sortingName !== "asc" ? (
                      <ArrowDropUpIcon
                        onClick={() => {
                          sort("User Name", "asc");
                        }}
                      />
                    ) : (
                      <CloseIcon
                        onClick={() => {
                          sort("User Name", "");
                        }}
                      />
                    )}
                  </>
                  <>
                    {sortingName !== "dsc" ? (
                      <ArrowDropDownIcon
                        onClick={() => {
                          sort("User Name", "dsc");
                        }}
                      />
                    ) : (
                      <CloseIcon
                        onClick={() => {
                          sort("User Name", "");
                        }}
                      />
                    )}
                  </>
                </Box>
              </Box>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <span>Declaration Date</span>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "4px",
                  }}
                >
                  <>
                    {sortingDate !== "asc" ? (
                      <ArrowDropUpIcon
                        onClick={() => {
                          sort("Declaration Date", "asc");
                        }}
                      />
                    ) : (
                      <CloseIcon
                        onClick={() => {
                          sort("Declaration Date", "");
                        }}
                      />
                    )}
                  </>
                  <>
                    {sortingDate !== "dsc" ? (
                      <ArrowDropDownIcon
                        onClick={() => {
                          sort("Declaration Date", "dsc");
                        }}
                      />
                    ) : (
                      <CloseIcon
                        onClick={() => {
                          sort("Declaration Date", "none");
                        }}
                      />
                    )}
                  </>
                </Box>
              </Box>
            </TableCell>
            <TableCell>Bank Number</TableCell>
            <TableCell>Note</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Total Items</TableCell>
            <TableCell>Total Price</TableCell>
            <TableCell>Total Mileage</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedArray?.map((row) => (
            <DeclarationTableRows
              users={users}
              row={row}
              key={row.id}
              getDeclarations={getDeclarations}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
