import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
export default function DashboardCard({ name, num }) {
  const Colors = (num) => {
    if (num > 10) {
      return "Black";
    }
    if (num <= 10) {
      return "Black";
    }
  };
  return (
    <Box>
      <Card sx={{ backgroundColor: "#fafafa", cursor: "pointer" }}>
        <CardContent>
          <>
            <Typography
              align="left"
              variant="caption"
              color="primary"
              noWrap
              sx={{ fontWeight: "bold", fontSize: "20px" }}
            >
              {name}
            </Typography>
            <Typography
              mt={2}
              noWrap
              align="center"
              sx={{
                color: Colors(num),
                fontWeight: "bold",
                fontSize: { md: "30px", sm: "20px", xs: "15px" },
              }}
            >
              {num}
            </Typography>
          </>
        </CardContent>
      </Card>
    </Box>
  );
}
