/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import TableComponent from "./LocationTableComponent";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useCookies } from "react-cookie";
import { Box, MenuItem, FormControl, Typography, Select } from "@mui/material";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import locationService from "../../Services/LocationService";
import AddLocationPopup from "../../Components/Popups/AddLocationPopup";
import customerService from "../../Services/CustomerService";

export default function Locations() {
  const [addLocation, setAddLocation] = React.useState(false);
  const [cookies] = useCookies(["JWTtoken"]);
  const [loading, setLoading] = React.useState(false);
  const [locations, setLocations] = React.useState([]);
  const [customerID, setCustomerID] = React.useState("ALL");
  const [customers, setCustomers] = React.useState([]);

  const handleChange = (event) => {
    setLoading(true);
    setCustomerID(event.target.value);

    locationService.getLocations(cookies.JWTtoken).then((data) => {
      setLoading(false);
      if (event.target.value === "ALL") {
        setLocations(data.data);
      } else {
        const filteredLocations = data.data.filter((user) =>
          user.customers.includes(event.target.value)
        );
        setLocations(filteredLocations);
      }
    });
  };
  const getLocations = () => {
    setLoading(true);
    locationService
      .getLocations(cookies.JWTtoken)
      .then((data) => {
        setLoading(false);
        setLocations(data.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setLocations([]);
      });
    customerService
      .GetCustomers(cookies.JWTtoken)
      .then((data) => {
        setLoading(false);
        setCustomers(data.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setCustomers([]);
      });
  };
  React.useEffect(getLocations, []);
  return (
    <>
      <LoadingScreen bool={loading} />
      {addLocation && (
        <AddLocationPopup
          mode="add"
          bool={addLocation}
          setBool={setAddLocation}
          getLocations={getLocations}
        />
      )}

      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: 16, right: "1%", color: "white" }}
        onClick={(e) => {
          setAddLocation(true);
        }}
      >
        <AddIcon />
      </Fab>
      <Box pt={10} ml={2} mr={2} sx={{ marginLeft: "220px" }}>
        <Box sx={{ display: "flex", alignItems: "end" }}>
          <Typography
            mr={2}
            color="primary"
            sx={{ fontWeight: "bold", fontSize: "22px" }}
          >
            Select Customer
          </Typography>
          <FormControl sx={{ width: "150px" }}>
            <Select value={customerID} onChange={handleChange}>
              <MenuItem value={"ALL"}>ALL</MenuItem>
              {customers?.map((data) => {
                return (
                  <MenuItem key={data.CustomerID} value={data.CustomerID}>
                    {data.CustomerID + " | " + data.Alias}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <TableComponent locations={locations} getLocations={getLocations} />
      </Box>
    </>
  );
}
