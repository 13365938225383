import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

import OtpHandler from "./OtpHandler";
import CloseIcon from "@mui/icons-material/Close";

const OtpModal = ({ bool, handleClose, token, email, onSuccess }) => {
  return (
    <Dialog
      fullWidth
      open={bool}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold", pb: 0, mb: 0 }}>
          Two Factor Authentication
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent
        sx={{
          minWidth: "350px",
          pt: 0,
          mt: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography>Type Your 6 Digit Code</Typography>
          <OtpHandler token={token} email={email} onSuccess={onSuccess} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OtpModal;
