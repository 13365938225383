import React, { useEffect, useState } from "react";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import { Box } from "@mui/material";
import ProgressConversationTable from "./components/progressConversationTable/ProgressConversationTable";
import progressConversationService from "../../Services/ProgressConversations";
import { useCookies } from "react-cookie";

const ProgressConversations = () => {
  const [cookies] = useCookies();
  const [loading, setLoading] = useState(false);
  const [progressConversations, setProgressConversations] = useState([]);

  const getProgressConversations = () => {
    progressConversationService
      .GetProgressConversations(cookies.JWTtoken)
      .then((response) => {
        setProgressConversations(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.data === "Forbidden") {
          window.location.href = "/";
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };
  useEffect(() => {
    setLoading(true);
    getProgressConversations();
  }, []);

  return (
    <>
      <LoadingScreen bool={loading} />
      <Box sx={{ width: "100%" }}>
        <ProgressConversationTable
          progressConversations={progressConversations}
          getProgressConversations={getProgressConversations}
        />
      </Box>
    </>
  );
};

export default ProgressConversations;
