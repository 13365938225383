import React, { useEffect, useState } from "react";
import ModalWrapper from "../../../../Components/ModalWrapper/ModalWrapper";
import { Box, TextField, Button } from "@mui/material";
import educationService from "../../../../Services/EducationService";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

const defaultEducation = {
  description: "",
  type: "",
  customers: "",
};
const AddEditEducation = ({ open, onClose, mode, onSuccess, editData }) => {
  const [cookies] = useCookies(["JWTtoken"]);
  const [education, setEducation] = useState(defaultEducation);

  useEffect(() => {
    if (mode === "Edit") {
      setEducation({
        ...education,
        description: editData.description,
        type: editData.type,
        customers: editData.customers,
      });
    }
  }, [editData, mode]);

  const createEducation = async () => {
    let res;
    if (mode === "Add") {
      res = await educationService.addEducation(education, cookies.JWTtoken);
    } else {
    education.educationID = editData.educationID
      res = await educationService.editEducation(education, cookies.JWTtoken);
    }
    if (res.status === 200) {
      toast.success(res.data, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      onSuccess();
    } else {
      toast.error(res.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      title={`${mode} Education`}
    >
      <Box>
        <TextField
          margin="normal"
          label="Type"
          value={education.type}
          onChange={(e) => {
            setEducation({ ...education, type: e.target.value });
          }}
        />
        <TextField
          margin="normal"
          label="Customers"
          value={education.customers}
          onChange={(e) => {
            setEducation({ ...education, customers: e.target.value });
          }}
        />
        <TextField
          margin="normal"
          variant="outlined"
          label="Description"
          multiline
          minRows={5}
          value={education.description}
          onChange={(e) => {
            setEducation({ ...education, description: e.target.value });
          }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "right" }}>
        <Box m={2}>
          <Button
            onClick={() => {
              createEducation();
            }}
          >
            {mode === "Add" ? "Create" : "Save"}
          </Button>
        </Box>
        <Box m={2}>
          <Button
            onClick={(e) => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default AddEditEducation;
