/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import TableComponent from "./TagsTableComponent";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useCookies } from "react-cookie";
import { Box, Select, FormControl, MenuItem, Typography } from "@mui/material";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import tagService from "../../Services/TagsService";
import CreateTagPopup from "../../Components/Popups/CreateTagPopup";
import gateWayService from "../../Services/GateWayService";
import { useState, useEffect } from "react";
import _ from "lodash";

const emailPattern = /[\w\.-]+@[\w\.-]+\.\w+/;

export const sortingTagsHeading = [
  "Tag ID",
  "Last RSSI",
  "Location",
  "Last Active",
  "Date Added",
];

export default function Tags() {
  const [createtagsPopup, setcreatetagsPopup] = useState(false);
  const [cookies] = useCookies(["JWTtoken"]);
  const [loading, setLoading] = useState(false);
  const [tags, settags] = useState([]);
  const [tagsCopy, setTagsCopy] = useState([]);
  const [gateway, setgateway] = useState([]);
  const [gatewayAlias, setgatewayAlias] = useState("ALL");
  const [firstLoad, setFirstLoad] = useState(true);
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("ALL");
  const [sorting, setSorting] = useState([]);

  const handleChange = (event) => {
    if (emailPattern.test(event.target.value)) {
      setSelectedEmail(event.target.value);
      setgatewayAlias("ALL");
    } else {
      setgatewayAlias(event.target.value);
      setSelectedEmail("ALL");
    }

    let temp = tagsCopy;
    if (event.target.value === "ALL") {
      settags(temp);
    } else {
      settags(
        temp.filter((mac) => {
          return mac.Location === event.target.value;
        })
      );
    }
  };

  const locationFilter = (location) => {
    let temp = tagsCopy;
    settags(
      temp.filter((gateway) => {
        return gateway.Location === location;
      })
    );
  };

  const gettags = () => {
    setLoading(true);
    Promise.all([
      tagService.getTags(cookies.JWTtoken),
      gateWayService.getGateWays(cookies.JWTtoken),
    ])
      .then(([tagsResponse, gatewaysResponse]) => {
        const tagsRes = tagsResponse.data.tags;
        const uniqueEmails = [
          ...new Set(
            tagsRes
              .filter((item) => emailPattern.test(item.Location))
              .map((item) => item.Location)
          ),
        ];

        settags(tagsRes);
        setTagsCopy(tagsRes);
        setEmails(uniqueEmails);
        setgateway(gatewaysResponse.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        settags([]);
        setgateway([]);
        if (error.response && error.response.data === "Forbidden") {
          window.location.href = "/";
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  useEffect(() => {
    gettags();
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    } else {
      SortingFunction();
    }
  }, [sorting]);

  const SortingFunction = () => {
    setLoading(true);

    setTimeout(() => {
      let sortedArray = [...tagsCopy];
      if (sorting.length > 0) {
        sorting.forEach(({ heading, order }) => {
          const sortingCriteriaMap = {
            "Last Active": "Date_Last_active",
            "Date Added": "Date_added",
            Location: "Location",
            "Last RSSI": "Last_rssi",
            "Tag ID": "TagID",
          };

          if (sortingCriteriaMap[heading]) {
            sortedArray = _.chain(sortedArray)
              .sortBy((item) => item[sortingCriteriaMap[heading]])
              .value();

            if (order === "dsc") {
              sortedArray = sortedArray.reverse();
            }
          }
        });
      }

      setLoading(false);
      settags(sortedArray);
    }, 3000);
  };

  return (
    <>
      <LoadingScreen bool={loading} />
      <CreateTagPopup
        bool={createtagsPopup}
        setbool={setcreatetagsPopup}
        gettags={gettags}
      />

      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: 16, right: "1%", color: "white" }}
        onClick={() => {
          setcreatetagsPopup(true);
        }}
      >
        <AddIcon />
      </Fab>
      <Box pt={10} ml={2} mr={2} sx={{ marginLeft: "220px" }}>
        <Box sx={{ display: "flex", alignItems: "end" }}>
          <Box sx={{ display: "flex", alignItems: "end", marginRight: "20px" }}>
            <Typography
              mr={2}
              color="primary"
              sx={{ fontWeight: "bold", fontSize: "22px" }}
            >
              Select Gateway
            </Typography>
            <FormControl sx={{ width: "150px" }}>
              <Select value={gatewayAlias} onChange={handleChange}>
                <MenuItem value={"ALL"}>ALL</MenuItem>
                {gateway.map((data) => {
                  return (
                    <MenuItem key={data.MAC} value={data.MAC}>
                      {data.Alias}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", alignItems: "end" }}>
            <Typography
              mr={2}
              color="primary"
              sx={{ fontWeight: "bold", fontSize: "22px" }}
            >
              Select Email
            </Typography>
            <FormControl sx={{ width: "150px" }}>
              <Select value={selectedEmail} onChange={handleChange}>
                <MenuItem value={"ALL"}>ALL</MenuItem>
                {emails.map((data) => {
                  return (
                    <MenuItem key={data} value={data}>
                      {data}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <TableComponent
          tags={tags}
          gettags={gettags}
          locationFilter={locationFilter}
          sorting={sorting}
          setSortingFn={(heading, order) => {
            if (sortingTagsHeading.includes(heading)) {
              if (order === "") {
                const updatedSorting = sorting.filter(
                  (srt) => srt.heading !== heading
                );
                setSorting(updatedSorting);
              } else {
                const updatedSorting = sorting.filter(
                  (srt) => srt.heading !== heading
                );

                setSorting([...updatedSorting, { heading, order }]);
              }
            }
          }}
        />
      </Box>
    </>
  );
}
