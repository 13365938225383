import { useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import moment from "moment";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCookies } from "react-cookie";
import tagService from "../../Services/TagsService";
import EditTagPopup from "../../Components/Popups/EditTagPopup";
import DeletePopup from "../../Components/Popups/DeletePopup";
import { Close } from "@mui/icons-material";
import { Box } from "@mui/material";
export default function TagsTableRows({ row, gettags, locationFilter }) {
  const [cookies] = useCookies(["JWTtoken"]);
  const [tagEdit, settagEdit] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [filter, setFilter] = useState(false);

  const handleClose = () => {
    setDeletePopup(!deletePopup);
  };

  const deleteTag = () => {
    tagService
      .deleteTag(row.TagID, cookies.JWTtoken)
      .then((data) => {
        gettags();
        toast.success(data.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const location = row.Alias
    ? row.Alias
    : row.Location
    ? row.Location
    : "(Never seen)";

  return (
    <>
      <DeletePopup
        confirmDelete={deleteTag}
        bool={deletePopup}
        handleClose={handleClose}
      />
      <EditTagPopup
        bool={tagEdit}
        setbool={settagEdit}
        gettags={gettags}
        row={row}
      />

      <TableRow
        key={row.TagID}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>{row.TagID}</TableCell>
        <TableCell>{row.Last_rssi ? row.Last_rssi : "(Never seen)"}</TableCell>
        <TableCell>{row.MAC}</TableCell>
        <TableCell>{row.fixed === 1 ? <b>&#10003;</b> : <>X</>}</TableCell>
        <TableCell>
          {row.ObjectNr ? (
            <>
              {row.ObjectNr !== "undefined" ? <>{row.ObjectNr}</> : <>N/A</>}{" "}
            </>
          ) : (
            <>N/A</>
          )}
        </TableCell>
        <TableCell>
          {row.vbatt ? (
            <>
              {row.vbatt !== 0 ? (
                <>
                  {
                    /* The batteryy voltage is divided by 1000 to get the actual voltage */
                    (row.vbatt / 1000).toFixed(2)
                  }{" "}
                  V
                </>
              ) : (
                <>N/A</>
              )}{" "}
            </>
          ) : (
            <>N/A</>
          )}
        </TableCell>
        <TableCell sx={{ cursor: "pointer", color: "blue" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              onClick={() => {
                locationFilter(row.Location);
                setFilter(true);
              }}
            >
              {location}
            </Box>
            {filter ? (
              <Box
                ml={1}
                onClick={() => {
                  gettags();
                  setFilter(false);
                }}
              >
                <Close />
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </TableCell>
        <TableCell>{row.Note ? row.Note : "N/A"}</TableCell>
        <TableCell>{row.Description ? row.Description : "N/A"}</TableCell>
        <TableCell>
          <b>{moment(row.Date_Last_active).format("DD MMMM YYYY HH:mm")}</b>
        </TableCell>
        <TableCell>
          {moment(row.Date_added).format("DD MMMM YYYY HH:mm")}
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => {
              settagEdit(true);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => {
              setDeletePopup(true);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
