/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import userService from "../../Services/UserService";
import TableComponent from "./TableComponent";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import RegisterPopup from "../../Components/Popups/RegisterPopup";
import { useCookies } from "react-cookie";
import { Box, FormControl, Select, Typography, MenuItem } from "@mui/material";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import customerService from "../../Services/CustomerService";
import EmailIcon from "@mui/icons-material/Email";
import { roles } from "../../Common/roles";
import NotificationReceivers from "../../Components/Popups/NotificationReceivers";

export default function Users() {
  const [registerpopup, setregisterpopup] = useState(false);
  const [cookies] = useCookies(["JWTtoken"]);
  const [loading, setLoading] = useState(false);
  const [emailPopup, setEmailPopup] = useState(false);
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState();
  const [customerID, setCustomerID] = useState("ALL");
  const [roleFilter, setRoleFilter] = useState("ALL");

  const handleChange = async (customer = "ALL", role = "ALL") => {
    try {
      setLoading(true);

      const response = await userService.GetUsers(cookies.JWTtoken);
      const data = response.data;

      let filteredUsers = [];

      if (customer === "ALL" && role === "ALL") {
        filteredUsers = data;
      } else {
        filteredUsers = data.filter(
          (user) => customer === "ALL" || user.customers.includes(customer)
        );

        if (role !== "ALL") {
          filteredUsers = filteredUsers.filter((user) => user.role === role);
        }
      }

      setCustomerID(customer);
      setRoleFilter(role);
      setUsers(filteredUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const getUsers = () => {
    setLoading(true);
    userService
      .GetUsers(cookies.JWTtoken)
      .then((data) => {
        setLoading(false);
        setUsers(data.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setUsers([]);
        if (error.response.data === "Forbidden") {
          window.location.href = "/";
        }
      });
    customerService.GetCustomers(cookies.JWTtoken).then((data) => {
      setLoading(false);
      setCustomers(data.data);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <LoadingScreen bool={loading} />
      {registerpopup && (
        <RegisterPopup
          bool={registerpopup}
          setbool={setregisterpopup}
          getUsers={getUsers}
        />
      )}
      {emailPopup && (
        <NotificationReceivers
          bool={emailPopup}
          setBool={setEmailPopup}
          users={users}
        />
      )}

      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: 16, right: "1%", color: "white" }}
        onClick={(e) => {
          setregisterpopup(true);
        }}
      >
        <AddIcon />
      </Fab>
      <Fab
        color="primary"
        aria-label="email"
        sx={{ position: "fixed", bottom: 16, right: "5%", color: "white" }}
        onClick={(e) => {
          setEmailPopup(true);
        }}
      >
        <EmailIcon />
      </Fab>
      <Box pt={10} ml={2} mr={2} sx={{ marginLeft: "220px" }}>
        <Box sx={{ display: "flex", alignItems: "end" }}>
          <Box sx={{ display: "flex", alignItems: "end", marginRight: "10px" }}>
            <Typography
              mr={2}
              color="primary"
              sx={{ fontWeight: "bold", fontSize: "22px" }}
            >
              Select Customer
            </Typography>
            <FormControl sx={{ width: "150px" }}>
              <Select
                value={customerID ? customerID : "ALL"}
                onChange={(e) => {
                  handleChange(e.target.value, roleFilter);
                }}
              >
                <MenuItem value={"ALL"}>ALL</MenuItem>
                {customers?.map((data) => {
                  return (
                    <MenuItem key={data.CustomerID} value={data.CustomerID}>
                      {data.CustomerID + " | " + data.Alias}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", alignItems: "end" }}>
            <Typography
              color="primary"
              mr={2}
              sx={{ fontWeight: "bold", fontSize: "22px" }}
            >
              Select Role
            </Typography>
            <FormControl sx={{ width: "150px" }}>
              <Select
                value={roleFilter ? roleFilter : "ALL"}
                onChange={(e) => {
                  handleChange(customerID, e.target.value);
                }}
              >
                <MenuItem value={"ALL"}>ALL</MenuItem>
                {roles?.map((role) => {
                  return (
                    <MenuItem key={role.id} value={role.value}>
                      {role.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <TableComponent users={users} getUsers={getUsers} />
      </Box>
    </>
  );
}
