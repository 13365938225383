/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import QRCode from "react-qr-code";
import userService from "../../../Services/UserService";
import OtpHandler from "./OtpHandler";
import googlePlayStore from "../../../assets/logo/gPlayStore.png";
import applePlayStore from "../../../assets/logo/AplayStore.png";

const TwoFactorAuth = ({ bool, handleClose, token, user, onSuccess }) => {
  const [shouldGenerate, setShouldGenerate] = useState(false);
  const [messageOnExisting, setMessageOnExisting] = useState("");
  const [userSecret, setUserSecret] = useState({});

  const get2FaToken = () => {
    userService
      .generate2FA({ email: user.email })
      .then((data) => {
        setUserSecret(data.data);
        setMessageOnExisting("");
        setShouldGenerate(true);
      })
      .catch((error) => {
        setUserSecret({});
        setMessageOnExisting(error.response.data.message);
      });
  };

  return (
    <Dialog
      fullWidth
      open={bool}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold", pb: 0, mb: 0 }}>
          Two Factor Authentication
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent
        sx={{
          minWidth: "350px",
          pt: 0,
          mt: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography align="center" variant="h7">
            Two-Factor Authentication (2FA) is mandatory for enhanced account
            security. Enable it now to ensure continued access to your account
          </Typography>
          <Typography mt={2} align="center" variant="h7">
            You can use Microsoft Authenticator Apps for&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en&gl=US"
              target="_blank"
            >
              Android
            </a>
            &nbsp;and&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en&gl=US"
              target="_blank"
            >
              IOS
            </a>
            &nbsp;devices.
          </Typography>
          <Box mt={2}>
            <a
              href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en&gl=US"
              target="_blank"
            >
              <img
                src={googlePlayStore}
                alt="Google Play Store"
                height={30}
                width={80}
                style={{
                  objectFit: "contain",
                }}
              />
            </a>
            &nbsp;&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en&gl=US"
              target="_blank"
            >
              <img
                src={applePlayStore}
                alt="Google Play Store"
                height={30}
                width={80}
                style={{
                  objectFit: "contain",
                }}
              />
            </a>
          </Box>

          {!shouldGenerate && (
            <Box mt={2} sx={{ display: "flex", gap: "20px" }}>
              <Button onClick={get2FaToken}>Generate Key</Button>
            </Box>
          )}
          <Box m={2}>
            <Typography color="red" fontWeight="bold">
              {!!messageOnExisting && messageOnExisting}
            </Typography>
          </Box>
          <Box>
            {!!shouldGenerate && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography fontWeight="bold">
                  Scan in the Authenticator app the QR code.
                </Typography>
                <QRCode value={userSecret.url} size={150} />
                <Typography variant="h6">OR</Typography>
                <Typography fontWeight="bold">Add manually the code</Typography>
                <Typography>{userSecret.secret}</Typography>

                <Typography fontWeight="bold">
                  When this is done, give up the first 2FA code you received.
                </Typography>
                <OtpHandler
                  token={token}
                  email={user.email}
                  onSuccess={onSuccess}
                />
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TwoFactorAuth;
